import "./Home.css";
import Banner from "./Banner";
import Education from "./Education";
import Service from "./Service";
import Pics from "./Pics";

import Testimonials from "./Testimonials";

import Form from "./Form";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Freq from "./Freq";
import { Title } from "../Title";
import Vivo from "../check";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  Title("Welcome - saamar");
  const [document] = useAllPrismicDocumentsByType("home_type");
  const slice = document && document[0]?.data;
  console.log(slice);

  return (
    <>
      <Banner bcontent={slice} />
      <Education econtent={slice} />
      <Service scontent={slice} />
      <Pics pcontent={slice} />
      {/* <Testimonials tcontent={slice} /> */}
      <Vivo />

      <Freq fcontent={slice} />
      <div>
        <h3 className="qrtu">
          <span>Contact</span> <span className="svi">Us</span>
        </h3>
        <iframe
          className="ifm"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.52065771147!2d77.63164354982256!3d13.066154790748701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19e632b4c2cb%3A0x152ba4502f226546!2sSaamar%20International%20Islamic%20School!5e0!3m2!1sen!2sin!4v1594640121557!5m2!1sen!2sin"
        ></iframe>
      </div>

      <Form />
    </>
  );
};
export default Home;
