import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import { useEffect, useState } from "react";
import logo1 from "../../assets/Image/logo1.png";
import logo2 from "../../assets/Image/logo2.png";
import { Link } from "react-router-dom";
function Header() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <nav
      className={
        isVisible
          ? "bg-primary-Sticky navbar navbar-expand-lg navbar-dark  bg-primary"
          : "navbar navbar-expand-lg  navbar-light bg-primary"
      }
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <img className="samar-logo" src={logo1} alt="" />
        </a>
        <div
          className={
            isVisible
              ? "saamar_logo_hd-sticky saamar_logo_hd"
              : "saamar_logo_hd"
          }
        >
          {" "}
          SAAMAR INTERNATIONAL
          <br /> ISLAMIC SCHOOL (SIIS) <br />
          <span className="under-sn">
            Under the Management of Jamia
            <br /> Muhammadiyah Education Society, Mumbai
          </span>{" "}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className={
                  isVisible
                    ? "tet-sticky nav-link  dropdown-toggle"
                    : "nav-link  dropdown-toggle"
                }
                href="#"
                data-bs-toggle="dropdown"
              >
                ABOUT US
              </a>
              <ul className="dropdown-menu">
                <Link to="/vision-mission-principles" className="dropdown-item">
                  Vision- Mission- Principles
                </Link>
                <Link to="/history" className="dropdown-item">
                  History
                </Link>
                <Link to="/management" className="dropdown-item">
                  Management
                </Link>
                <Link to="/key-details" className="dropdown-item">
                  Key Details
                </Link>
                <Link to="/the-team" className="dropdown-item">
                  The Team
                </Link>
                {/* <Link to="" className="dropdown-item">
                  Previous Chapters Of SIIS
                </Link> */}
                <li>
                  <a className="dropdown-item" href="">
                    Previous Chapters Of SIIS
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={
                  isVisible
                    ? "tet-sticky nav-link  dropdown-toggle"
                    : "nav-link  dropdown-toggle"
                }
                href="#"
                data-bs-toggle="dropdown"
              >
                CURRICULUM
              </a>
              <ul className="dropdown-menu">
                <Link to="/syllabus" className="dropdown-item">
                  Syllabus
                </Link>
                <Link to="/teaching-and-learning" className="dropdown-item">
                  Teaching And Learning
                </Link>
                <Link to="/teaching-pedagogy" className="dropdown-item">
                  Teaching Pedagogy
                </Link>
                <Link
                  to="/extra-curricular-activities"
                  className="dropdown-item"
                >
                  Extra Curricular Activities
                </Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={
                  isVisible
                    ? "tet-sticky nav-link  dropdown-toggle"
                    : "nav-link  dropdown-toggle"
                }
                href="#"
                data-bs-toggle="dropdown"
              >
                INFRASTRUCTURE
              </a>
              <ul className="dropdown-menu">
                <Link to="/environment" className="dropdown-item">
                  Environment
                </Link>
                <Link to="/facilities" className="dropdown-item">
                  Facilities
                </Link>
                <Link to="/school-campus" className="dropdown-item">
                  School Campus
                </Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={
                  isVisible
                    ? "tet-sticky nav-link  dropdown-toggle"
                    : "nav-link  dropdown-toggle"
                }
                href="#"
                data-bs-toggle="dropdown"
              >
                ADMISSION
              </a>
              <ul className="dropdown-menu">
                <Link to="/criteria" className="dropdown-item">
                  Criteria
                </Link>
                <Link to="/procedure" className="dropdown-item">
                  Procedure
                </Link>
                <Link to="/form" className="dropdown-item">
                  Forms
                </Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={
                  isVisible
                    ? "tet-sticky nav-link  dropdown-toggle"
                    : "nav-link  dropdown-toggle"
                }
                href="#"
                data-bs-toggle="dropdown"
              >
                INFORMATION
              </a>
              <ul className="dropdown-menu">
                <Link to="/to-parents" className="dropdown-item">
                  To Parents
                </Link>
                <Link to="/to-students" className="dropdown-item">
                  To Students
                </Link>
                <Link to="/to-staff-members" className="dropdown-item">
                  To Staff Members
                </Link>
                <Link to="/to-school-visitors" className="dropdown-item">
                  To School Visitors
                </Link>
                <Link to="/to-website-visitors" className="dropdown-item">
                  To Website Visitors
                </Link>
                <Link
                  to="/to-candidates-for-recruitment"
                  className="dropdown-item"
                >
                  To Candidates For Recruitment (CFR)
                </Link>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={
                  isVisible
                    ? "tet-sticky nav-link  dropdown-toggle"
                    : "nav-link  dropdown-toggle"
                }
                href="#"
                data-bs-toggle="dropdown"
              >
                GALLERY
              </a>
              <ul className="dropdown-menu">
                <Link to="/national-events" className="dropdown-item">
                  National Events
                </Link>
                <Link to="/annual-events" className="dropdown-item">
                  Annual Events
                </Link>
                <Link to="/ongoing-activities" className="dropdown-item">
                  Ongoing Activities
                </Link>
                <Link to="/other" className="dropdown-item">
                  Other
                </Link>
                {/* <Link to="" className="dropdown-item">
                  Memory Lane
                </Link> */}
                <li>
                  <a className="dropdown-item" href="">
                    Memory Lane
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className={
                  isVisible
                    ? "tet-sticky nav-link  dropdown-toggle"
                    : "nav-link  dropdown-toggle"
                }
                href="#"
                data-bs-toggle="dropdown"
              >
                NOTICE BOARD
              </a>
              <ul className="dropdown-menu">
                <Link to="/calendar-for-the-year" className="dropdown-item">
                  Calendar For The Year
                </Link>
                <Link to="/ongoing-events" className="dropdown-item">
                  Ongoing Events
                </Link>
                <Link to="/circulars" className="dropdown-item">
                  Circulars
                </Link>
                <Link to="/announcements" className="dropdown-item">
                  Announcements
                </Link>
                {/* <Link to="" className="dropdown-item">
                  Blogs And Vlogs
                </Link> */}
                <li>
                  <a className="dropdown-item" href="">
                    Blogs And Vlogs
                  </a>
                </li>
              </ul>
            </li>
          </ul>{" "}
          <img src={logo2} alt="" className="lo2" />
        </div>
      </div>
    </nav>
  );
}
export default Header;
