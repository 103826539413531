import { useAllPrismicDocumentsByType } from "@prismicio/react";
import {
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <MDBFooter
      bgColor="light"
      className="foo-main text-center text-lg-start text-muted"
    >
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3  cdf">
            <MDBCol md="4" className="mx-auto">
              <h4 className="text-uppercase mgr fw-bold ">KEEP IN TOUCH</h4>
              <p className="ft">
                THANISANDRA MAIN ROAD, R. K. HEGDE NAGAR,
                <br /> BANGALORE, KARNATAKA, 560 077
                <br /> +91 - 8028443545, +91 - 8028443546
                <br /> 7406038201, 9482312675<br></br>
              </p>
            </MDBCol>
            <MDBCol md="4" className="mx-auto ">
              <h6 className="text-uppercase fw-bold mgr ">IMPORTANT LINK</h6>
              <Link to="flyer" className="text-reset">
                FLYER
              </Link>
              <br />
              <Link to="brochure" className="text-reset">
                BROCHURE
              </Link>
              <br />
              <a href="/" className="text-reset">
                APPLICATION FORM
              </a>
              <br />
              {/* <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSeCqpQRF9FV5fNr4W4yxNaATzpyCrZkDonGhbLqC7w_rLBsXQ/viewform"
                className="text-reset"
              >
                PAYMENT NOTIFICATION FORM
              </Link> */}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeCqpQRF9FV5fNr4W4yxNaATzpyCrZkDonGhbLqC7w_rLBsXQ/viewform"
                className="text-reset"
              >
                PAYMENT NOTIFICATION FORM
              </a>
              <br />
              <a href="/" className="text-reset">
                JOB RECRUITMENT QUESTIONNAIRE
              </a>
              <br />
              <a href="/" className="text-reset">
                PRIVACY POLICY
              </a>
              <br />
            </MDBCol>

            <MDBCol md="4" className="mx-auto mb-md-0 ">
              <h6 className="text-uppercase fw-bold mgr ">FOLLOW US</h6>

              <div className="iol">
                <a href="https://www.facebook.com/" className="mx-1 text-reset">
                  <MDBIcon fab icon="facebook-f" />
                </a>
                <a
                  href="https://twitter.com/i/flow/login?input_flow_data=%7B%22requested_variant%22%3A%22eyJsYW5nIjoiZW4ifQ%3D%3D%22%7D"
                  className="mx-1 text-reset"
                >
                  <MDBIcon fab icon="twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/uas/login-submit"
                  className="mx-1 text-reset"
                >
                  <MDBIcon fab icon="linkedin" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCBUIup64irasvAzlzFV2HJg"
                  className="mx-1 text-reset"
                >
                  <MDBIcon fab icon="youtube" />
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        All Rights Reserved - © Copyright 2023 - Designed By Esquare Info
        Solutions
      </div>
    </MDBFooter>
  );
};
export default Footer;
