import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./School.css";
const School = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("School-Campus-saamar");
  const [document] = useAllPrismicDocumentsByType("infrastructure");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.ebanner.url} alt="" className="sgi" />
      <div className="container pt-4 mt-4" style={{ textAlign: "justify" }}>
        <ul>
          <li className="sit">{slice?.body[2]?.items[0].stext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[0].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[1].stext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[1].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[2].stext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[2].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[3].stext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[3].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[4].stext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[4].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[5].stext[0].text}</li>
          <li className="sit">{slice?.body[2]?.items[5].stext[1].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[5].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[6].stext[0].text}</li>
          <li className="sit">{slice?.body[2]?.items[6].stext[1].text}</li>
          <li className="sit">{slice?.body[2]?.items[6].stext[2].text}</li>
          <li className="sit">{slice?.body[2]?.items[6].stext[3].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[6].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[7].stext[0].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[1].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[2].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[3].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[4].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[5].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[6].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[7].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[8].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[9].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[10].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[11].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[12].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[13].text}</li>
          <li className="sit">{slice?.body[2]?.items[7].stext[14].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[7].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[8].stext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[8].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[9].stext[0].text}</li>
        </ul>
        <ul>
          <li className="sit">{slice?.body[2]?.items[9].stext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[9].simg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[2]?.items[10].stext[0].text}</li>
          <li className="sit">{slice?.body[2]?.items[10].stext[1].text}</li>
          <li className="sit">{slice?.body[2]?.items[10].stext[2].text}</li>
          <li className="sit">{slice?.body[2]?.items[10].stext[3].text}</li>
        </ul>
      </div>
    </div>
  );
};
export default School;
