import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Calendar.css";
const Calendar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Calendar for The Year - saamar");
  const [document] = useAllPrismicDocumentsByType("notice_board");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-5 mt-2 mb-3">
        <p className="cal-text">
          Quick reference guide: Table of Contents in the child’s diary.
        </p>
        <table className="table table-bordered cal-table">
          <thead>
            <tr>
              <th>Sl.No</th>
              <th style={{ textAlign: "center" }}>
                {slice?.body[0]?.items[0].calhead[0].text}
              </th>
              <th>Page Nos</th>
            </tr>
          </thead>
          <tbody className="cal-table-data">
            <tr>
              <td>1</td>
              <td>{slice?.body[0]?.items[0].callist[0].text}</td>
              <td>I</td>
            </tr>
            <tr>
              <td>2</td>
              <td>{slice?.body[0]?.items[0].callist[1].text}</td>
              <td>II</td>
            </tr>
            <tr>
              <td>3</td>
              <td>{slice?.body[0]?.items[0].callist[2].text}</td>
              <td>III</td>
            </tr>
            <tr>
              <td>4</td>
              <td>{slice?.body[0]?.items[0].callist[3].text}</td>
              <td>IV</td>
            </tr>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>
                {slice?.body[0]?.items[1].calhead[0].text}
              </th>
              <th>1</th>
            </tr>
            <tr>
              <td>5</td>
              <td>{slice?.body[0]?.items[1].callist[0].text}</td>
              <td>2</td>
            </tr>
            <tr>
              <td>6</td>
              <td>{slice?.body[0]?.items[1].callist[1].text}</td>
              <td>3</td>
            </tr>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>
                {slice?.body[0]?.items[2].calhead[0].text}
              </th>
              <th>4</th>
            </tr>
            <tr>
              <td>7</td>
              <td>{slice?.body[0]?.items[2].callist[0].text}</td>
              <td>5</td>
            </tr>
            <tr>
              <td>8</td>
              <td>{slice?.body[0]?.items[2].callist[1].text}</td>
              <td>6</td>
            </tr>
            <tr>
              <td>9</td>
              <td>{slice?.body[0]?.items[2].callist[2].text}</td>
              <td>7</td>
            </tr>
            <tr>
              <td>10</td>
              <td>{slice?.body[0]?.items[2].callist[3].text}</td>
              <td>8</td>
            </tr>
            <tr>
              <td>11</td>
              <td>{slice?.body[0]?.items[2].callist[4].text}</td>
              <td>9</td>
            </tr>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>
                {slice?.body[0]?.items[2].calhead[0].text}
              </th>
              <th>10</th>
            </tr>
            <tr>
              <td>12</td>
              <td>{slice?.body[0]?.items[3].callist[0].text}</td>
              <td>11-17</td>
            </tr>
            <tr>
              <td>13</td>
              <td>{slice?.body[0]?.items[3].callist[1].text}</td>
              <td>18</td>
            </tr>
            <tr>
              <td>14</td>
              <td>{slice?.body[0]?.items[3].callist[2].text}</td>
              <td>19</td>
            </tr>
            <tr>
              <td>15</td>
              <td>{slice?.body[0]?.items[3].callist[3].text}</td>
              <td>20</td>
            </tr>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>
                {slice?.body[0]?.items[4].calhead[0].text}
              </th>
              <th>21</th>
            </tr>
            <tr>
              <td>16</td>
              <td>{slice?.body[0]?.items[4].callist[0].text}</td>
              <td>22</td>
            </tr>
            <tr>
              <td>17</td>
              <td>{slice?.body[0]?.items[4].callist[1].text}</td>
              <td>23</td>
            </tr>
            <tr>
              <td>18</td>
              <td>{slice?.body[0]?.items[4].callist[2].text}</td>
              <td>24</td>
            </tr>
            <tr>
              <td>19</td>
              <td>{slice?.body[0]?.items[4].callist[3].text}</td>
              <td>25-27</td>
            </tr>
            <tr>
              <td>20</td>
              <td>{slice?.body[0]?.items[4].callist[4].text}</td>
              <td>28-30</td>
            </tr>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>
                {slice?.body[0]?.items[5].calhead[0].text}
              </th>
              <th>31</th>
            </tr>
            <tr>
              <td>21</td>
              <td>{slice?.body[0]?.items[5].callist[0].text}</td>
              <td>32</td>
            </tr>
            <tr>
              <td>22</td>
              <td>{slice?.body[0]?.items[5].callist[1].text}</td>
              <td>33</td>
            </tr>

            <tr>
              <td>23</td>
              <td>{slice?.body[0]?.items[5].callist[2].text}</td>
              <td>34</td>
            </tr>
            <tr>
              <td>24</td>
              <td>{slice?.body[0]?.items[5].callist[3].text}</td>
              <td>35</td>
            </tr>
            <tr>
              <td>25</td>
              <td>{slice?.body[0]?.items[5].callist[5].text}</td>
              <td>36-37</td>
            </tr>
            <tr>
              <td>26</td>
              <td>{slice?.body[0]?.items[5].callist[6].text}</td>
              <td>38</td>
            </tr>
            <tr>
              <td>27</td>
              <td>{slice?.body[0]?.items[5].callist[7].text}</td>
              <td>39</td>
            </tr>
            <tr>
              <td>28</td>
              <td>{slice?.body[0]?.items[5].callist[8].text}</td>
              <td>40-41</td>
            </tr>
            <tr>
              <td>29</td>
              <td>{slice?.body[0]?.items[5].callist[9].text}</td>
              <td>42</td>
            </tr>
            <tr>
              <td>30</td>
              <td>{slice?.body[0]?.items[5].callist[10].text}</td>
              <td>43-44</td>
            </tr>
            <tr>
              <td>31</td>
              <td>{slice?.body[0]?.items[5].callist[11].text}</td>
              <td>45</td>
            </tr>
            <tr>
              <td>32</td>
              <td>{slice?.body[0]?.items[5].callist[0].text}</td>
              <td>46 - 47</td>
            </tr>
            <tr>
              <td>33</td>
              <td>{slice?.body[0]?.items[5].callist[0].text}</td>
              <td>48</td>
            </tr>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>
                {slice?.body[0]?.items[6].calhead[0].text}
              </th>
              <th>49</th>
            </tr>
            <tr>
              <td>34</td>
              <td>{slice?.body[0]?.items[6].callist[0].text}</td>
              <td>50-51</td>
            </tr>
            <tr>
              <td>35</td>
              <td>{slice?.body[0]?.items[6].callist[1].text}</td>
              <td>52-61</td>
            </tr>
            <tr>
              <td>36</td>
              <td>{slice?.body[0]?.items[6].callist[2].text}</td>
              <td>62-212</td>
            </tr>
            <tr>
              <td>37</td>
              <td>{slice?.body[0]?.items[6].callist[3].text}</td>
              <td>213-214</td>
            </tr>
            <tr>
              <td>38</td>
              <td>{slice?.body[0]?.items[6].callist[4].text}</td>
              <td>--</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Calendar;
