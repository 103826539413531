import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Procedure.css";
const Procedure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Procedure-saamar");
  const [document] = useAllPrismicDocumentsByType("admission");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.bannerimg.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container  mt-5 pt-3">
        <h4 className="pro-head">
          {slice
            ? slice?.body[1]?.items[0].protopic[0].text
            : "Step 1: Enquiry w.r.t curriculum, fees and all other details"}
        </h4>
        <p>
          <span className="pro-txt">
            {slice
              ? slice?.body[1]?.items[0].propara[0].text
              : "This can be carried out over the phone or at the Front Office by meeting our Front Office Executive(FOE) or online. Pls feel free to call 7406038201 or mail"}
          </span>
          <span className="pro-id">
            {slice
              ? slice?.body[1]?.items[1].propara[0].text
              : "officesiis@gmail.com"}
          </span>
        </p>
        <h4 className="pro-head">
          {slice
            ? slice?.body[1]?.items[1].protopic[0].text
            : "Step 1: Enquiry w.r.t curriculum, fees and all other details"}
        </h4>

        <p className="pro-txt">
          {slice
            ? slice?.body[1]?.items[2].propara[0].text
            : "Fill in and submit the same. Hard copy option and online option are available."}
          <br />

          {slice
            ? slice?.body[1]?.items[3].propara[0].text
            : "Application form charges: Rs.100/-"}
        </p>
        <h4 className="pro-head">
          {slice
            ? slice?.body[1]?.items[2].protopic[0].text
            : "Step 3: Supporting documents to be submitted according to the checklist."}
        </h4>
        <div className="pro-list">
          <ol>
            <li>
              {slice
                ? slice?.body[1]?.items[4].propara[0].text
                : "For age – copy of the Birth Certificate"}
            </li>
            <li>
              {slice
                ? slice?.body[1]?.items[4].propara[1].text
                : "For age – copy of the Birth Certificate"}
            </li>
            <li>
              {slice
                ? slice?.body[1]?.items[4].propara[2].text
                : "For age – copy of the Birth Certificate"}
            </li>
            <li>
              {slice
                ? slice?.body[1]?.items[4].propara[3].text
                : "For age – copy of the Birth Certificate"}
            </li>
            <li>
              {slice
                ? slice?.body[1]?.items[4].propara[4].text
                : "For age – copy of the Birth Certificate"}
            </li>
            <li>
              {slice
                ? slice?.body[1]?.items[4].propara[5].text
                : "For age – copy of the Birth Certificate"}
            </li>
            <li>
              {slice
                ? slice?.body[1]?.items[4].propara[6].text
                : "For age – copy of the Birth Certificate"}
            </li>
          </ol>
        </div>
        <h4 className="pro-head">
          {slice
            ? slice?.body[1]?.items[3].protopic[0].text
            : "Step 4: Evaluation/Diagnosis:"}
        </h4>
        <p className="pro-txt">
          {slice
            ? slice?.body[1]?.items[5].propara[0].text
            : "In case of students seeking admission to Grade 1 and above: If this is required, in addition to the Diagnostic test, we shall let you know."}
          <br />
          <br />
          {slice
            ? slice?.body[1]?.items[6].propara[0].text
            : "In case of children seeking admission to the Montessori section: The evaluation is compulsorily done on a one to one basis by a Montessori teacher. The questions are posed orally and the response is recorded."}
        </p>
        <h4 className="pro-head">
          {slice
            ? slice?.body[1]?.items[4].protopic[0].text
            : "Step 5: Confirmation:"}
        </h4>
        <p className="pro-txt">
          {slice
            ? slice?.body[1]?.items[7].propara[0].text
            : "The school will revert w.r.t admission based on all the details/data collected under each of the steps for the criteria mentioned above."}
        </p>
        <h4 className="pro-head">
          {slice
            ? slice?.body[1]?.items[5].protopic[0].text
            : "Step 6: Payment of fees and Collection of resources:"}
        </h4>
        <p className="pro-txt">
          {slice
            ? slice?.body[1]?.items[8].propara[0].text
            : "The parents must pay the starter fee and collect the school resources including the school diary which has all details clearly outlined in it."}
        </p>
        <p>
          <span className="pro-info">
            {slice
              ? slice?.body[1]?.items[6].protopic[0].text
              : "School Transport:"}
          </span>
          <span className="pro-txt">
            {slice
              ? slice?.body[1]?.items[9].propara[0].text
              : " is on a need basis. Parents opting for transport for their child must fill in a separate form and pay the fees according to the slabs."}
          </span>
        </p>
        <p>
          <span className="pro-info">
            {slice
              ? slice?.body[1]?.items[7].protopic[0].text
              : "Private Transport:"}
          </span>
          <span className="pro-txt">
            {slice
              ? slice?.body[1]?.items[10].propara[0].text
              : "parents need to fill in the Private Transport Declaration Form(PTDF)."}
          </span>
        </p>
        <h4 className="pro-head">
          {slice
            ? slice?.body[1]?.items[8].protopic[0].text
            : "Step 7: Attend the Parents Orientation Programmes(POP) :"}
        </h4>
        <p className="pro-txt">
          {" "}
          {slice
            ? slice?.body[1]?.items[11].propara[0].text
            : "parents need to fill in the Private Transport Declaration Form(PTDF)."}
        </p>
      </div>
    </div>
  );
};
export default Procedure;
