import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Staff.css";
const Staff = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("To Staff Members-saamar");
  const [document] = useAllPrismicDocumentsByType("information");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-5">
        <p className="staff-top staff-headr-r">{slice?.body[2]?.primary.title[0].text} </p>
        <p className="staff-top staff-headr-r">
          {slice?.body[2]?.items[0].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[0].staffpara[0].text}{" "}
        </p>
        <div style={{ textAlign: "center" }}>
          <img
            src={slice?.body[2]?.items[0].staffimg.url}
            alt=""
            className=""
          />
        </div>
        <p className="staff-top staff-headr-r">
          1.{slice?.body[2]?.items[1].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[1].staffpara[0].text}{" "}
        </p>
        <p className="staff-top staff-headr-r">
          2.{slice?.body[2]?.items[2].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[2].staffpara[0].text}{" "}
        </p>
        <div style={{ textAlign: "center" }}>
          <img
            src={slice?.body[2]?.items[1].staffimg.url}
            alt=""
            className=""
          />
        </div>
        <p className="staff-top staff-headr-r">
          3.{slice?.body[2]?.items[3].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[3].staffpara[0].text}{" "}
        </p>
        <p className="staff-top staff-headr-r">
          4.{slice?.body[2]?.items[4].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[4].staffpara[0].text}{" "}
        </p>
        <div style={{ textAlign: "center" }}>
          <img
            src={slice?.body[2]?.items[2].staffimg.url}
            alt=""
            className=""
          />
        </div>
        <p className="staff-top staff-headr-r">
          5.{slice?.body[2]?.items[5].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[5].staffpara[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[5].staffpara[1].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[5].staffpara[2].text}{" "}
        </p>
        <div style={{ textAlign: "center" }}>
          <img
            src={slice?.body[2]?.items[3].staffimg.url}
            alt=""
            className=""
          />
        </div>
        <p className="staff-top staff-headr-r">
          6.{slice?.body[2]?.items[6].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[6].staffpara[0].text}{" "}
        </p>
        <p className="staff-top staff-headr-r">
          7.{slice?.body[2]?.items[7].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[7].staffpara[0].text}{" "}
        </p>
        <p className="staff-top staff-headr-r">
          8.{slice?.body[2]?.items[8].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[8].staffpara[0].text}{" "}
        </p>
        <div style={{ textAlign: "center" }}>
          <img
            src={slice?.body[2]?.items[4].staffimg.url}
            alt=""
            className="staff-ige" 
          />
        </div>
        <p className="staff-top staff-headr-r">
          9.{slice?.body[2]?.items[9].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[9].staffpara[0].text}{" "}
        </p>
        <p className="staff-top staff-headr-r">
          10.{slice?.body[2]?.items[10].staffhead[0].text}{" "}
        </p>
        <p className="culum-extr-p">
          {slice?.body[2]?.items[10].staffpara[0].text}{" "}
        </p>
        <h3 className="nfo">
          Don’t forget that afterall, you really are a role-model for your
          students.
        </h3>
        <div className="staff-design">
          {" "}
          <h4>Make your Connection …….</h4> <h4>………Before the Correction ……</h4>{" "}
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={slice?.body[2]?.items[5].staffimg.url}
              alt=""
              className="staff-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={slice?.body[2]?.items[6].staffimg.url}
              alt=""
              className="staff-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={slice?.body[2]?.items[7].staffimg.url}
              alt=""
              className="staff-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Staff;
