import "./App.css";
import Home from "./components/home/Home";
import { Route, Routes } from "react-router-dom";
import Vision from "./components/aboutus/Vision-mission-principles";
import History from "./components/aboutus/History";
import "aos/dist/aos.css";
import "aos/dist/aos.js";
import { useEffect } from "react";
import Aos from "aos";
import Management from "./components/aboutus/Management";
import Keydetails from "./components/aboutus/Keydetails";
import Nationalevents from "./components/gallery/Nationalevents";
import Annualevents from "./components/gallery/Annualevents";
import Ongoing from "./components/gallery/Ongoing";
import Other from "./components/gallery/Other";
import Form from "./components/admission/Form";
import Procedure from "./components/admission/Procedure";
import Criteria from "./components/admission/Criteria";
import Team from "./components/aboutus/Team";
import Environment from "./components/infrastructure/Environment";
import Facilities from "./components/infrastructure/Facilities";
import School from "./components/infrastructure/School";
import Syllabus from "./components/curriculum/Syllabus";
import Learning from "./components/curriculum/Learning";
import Teaching from "./components/curriculum/Teaching";
import Curricular from "./components/curriculum/Curricular";
import Announcement from "./components/Nboard/Announcement";
import Event from "./components/Nboard/Event";
import Circular from "./components/Nboard/Circular";
import Calendar from "./components/Nboard/Calendar";
import Flyer from "./components/footer/Flyer";
import Brochure from "./components/footer/Brochure";
import Student from "./components/information/Student";
import Candidate from "./components/information/Candidate";
import School1 from "./components/information/School";
import Staff from "./components/information/Staff";
import Website1 from "./components/information/Website";
import Parent from "./components/information/Parent";
import Vivo from "./components/check";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/vision-mission-principles" element={<Vision />}></Route>
        <Route path="/history" element={<History />}></Route>
        <Route path="/management" element={<Management />}></Route>
        <Route path="/key-details" element={<Keydetails />}></Route>
        <Route path="/the-team" element={<Team />}></Route>

        <Route path="/syllabus" element={<Syllabus />}></Route>
        <Route path="/teaching-and-learning" element={<Learning />}></Route>
        <Route path="/teaching-pedagogy" element={<Teaching />}></Route>
        <Route
          path="/extra-curricular-activities"
          element={<Curricular />}
        ></Route>

        <Route path="/criteria" element={<Criteria />}></Route>
        <Route path="/procedure" element={<Procedure />}></Route>
        <Route path="/form" element={<Form />}></Route>

        <Route path="/environment" element={<Environment />}></Route>
        <Route path="/facilities" element={<Facilities />}></Route>
        <Route path="/school-campus" element={<School />}></Route>

        <Route path="/national-events" element={<Nationalevents />}></Route>
        <Route path="/annual-events" element={<Annualevents />}></Route>
        <Route path="/ongoing-activities" element={<Ongoing />}></Route>
        <Route path="/other" element={<Other />}></Route>

        <Route path="/announcements" element={<Announcement />}></Route>
        <Route path="/ongoing-events" element={<Event />}></Route>
        <Route path="/circulars" element={<Circular />}></Route>
        <Route path="/calendar-for-the-year" element={<Calendar />}></Route>

        <Route path="/to-students" element={<Student />}></Route>
        <Route
          path="/to-candidates-for-recruitment"
          element={<Candidate />}
        ></Route>
        <Route path="/to-school-visitors" element={<School1 />}></Route>
        <Route path="/to-staff-members" element={<Staff />}></Route>
        <Route path="/to-website-visitors" element={<Website1 />}></Route>
        <Route path="/to-parents" element={<Parent />}></Route>

        <Route path="/flyer" element={<Flyer />}></Route>
        <Route path="/brochure" element={<Brochure />}></Route>
        <Route path="/vivo" element={<Vivo />}></Route>
      </Routes>
    </div>
  );
}

export default App;
