import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Brochure.css";
const Brochure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Brochure-saamar");
  const [document] = useAllPrismicDocumentsByType("footer");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.banner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-6">
            <img
              src={
                slice
                  ? slice?.body[1]?.items[0].brochureimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="saamar-SIIS-img"
            />
          </div>
          <div className="col-md-6">
            {" "}
            <img
              src={
                slice
                  ? slice?.body[1]?.items[1].brochureimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="saamar-SIIS-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img
              src={
                slice
                  ? slice?.body[1]?.items[2].brochureimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="saamar-SIIS-img"
            />
          </div>
          <div className="col-md-6">
            <img
              src={
                slice
                  ? slice?.body[1]?.items[3].brochureimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="saamar-SIIS-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Brochure;
