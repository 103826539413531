import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./syllabus.css";
const Syllabus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Syllabus-saamar");
  const [document] = useAllPrismicDocumentsByType("curriculum");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.sybanner.url} alt="" className="sgi" />
      <div className="container oty pt-4">
        <h4 className="sys-head">{slice?.body[0]?.items[0].sytext[0].text}</h4>
        <p className="sys-text">{slice?.body[0]?.items[1].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[2].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[3].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[4].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[5].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[6].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[7].sytext[0].text}</p>
        <p className="sys-text">
          <ul>
            <li>{slice?.body[0]?.items[8].sytext[0].text}</li>
            <li>{slice?.body[0]?.items[8].sytext[1].text}</li>
            <li>{slice?.body[0]?.items[8].sytext[2].text}</li>
            <li>{slice?.body[0]?.items[8].sytext[3].text}</li>
            <li>{slice?.body[0]?.items[8].sytext[4].text}</li>
            <li>{slice?.body[0]?.items[8].sytext[5].text}</li>
            <li>{slice?.body[0]?.items[8].sytext[6].text}</li>
          </ul>
        </p>
        <p className="sys-text">{slice?.body[0]?.items[9].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[10].sytext[0].text}</p>
        <p className="sys-text">
          <ul>
            <li>{slice?.body[0]?.items[11].sytext[0].text}</li>
            <li>{slice?.body[0]?.items[11].sytext[1].text}</li>
          </ul>
        </p>
        <p className="sys-text">{slice?.body[0]?.items[12].sytext[0].text}</p>
        <p className="sys-text">
          <ul>
            <li>{slice?.body[0]?.items[13].sytext[0].text}</li>
            <li>{slice?.body[0]?.items[13].sytext[1].text}</li>
          </ul>
        </p>
        <p className="sys-text">{slice?.body[0]?.items[14].sytext[0].text}</p>
        <h4 className="sys-head">{slice?.body[0]?.items[15].sytext[0].text}</h4>
        <img src={slice?.body[0]?.items[0].syimg.url} alt="" className="otop" />
        <h4 className="sys-head">{slice?.body[0]?.items[16].sytext[0].text}</h4>
        <p className="sys-text">{slice?.body[0]?.items[17].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[18].sytext[0].text}</p>
        <h4 className="sys-head">{slice?.body[0]?.items[19].sytext[0].text}</h4>
        <p className="sys-text">{slice?.body[0]?.items[20].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[21].sytext[0].text}</p>
        <h6 className="sys-head">{slice?.body[0]?.items[22].sytext[0].text}</h6>
        <p className="sys-text">
          <ul>
            <li>{slice?.body[0]?.items[23].sytext[0].text}</li>
            <li>{slice?.body[0]?.items[23].sytext[1].text}</li>
            <li>{slice?.body[0]?.items[23].sytext[2].text}</li>
            <li>{slice?.body[0]?.items[23].sytext[3].text}</li>
            <li>{slice?.body[0]?.items[23].sytext[4].text}</li>
            <li>{slice?.body[0]?.items[23].sytext[5].text}</li>
            <li>{slice?.body[0]?.items[23].sytext[6].text}</li>
          </ul>
        </p>
        <h6 className="sys-head">{slice?.body[0]?.items[24].sytext[0].text}</h6>
        <p className="sys-text">{slice?.body[0]?.items[25].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[26].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[27].sytext[0].text}</p>
        <p className="sys-text">{slice?.body[0]?.items[28].sytext[0].text}</p>

        <img src={slice?.body[0]?.items[1].syimg.url} alt="" className="" />
      </div>
    </div>
  );
};
export default Syllabus;
