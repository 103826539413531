import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Ongoing.css";
const Ongoing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Ongoing Activities - saamar");
  const [document] = useAllPrismicDocumentsByType("gallery");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.bannerimg.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container pt-4 mt-4">
        <div className="row">
          <p className="ongoing-txt">
            {slice
              ? slice?.body[2]?.items[0].ontext[0].text
              : "KAS (Knowledge-Application-Skills) Fest"}
          </p>
          <p className="ongoing-txt">
            {slice
              ? slice?.body[2]?.items[1].ontext[0].text
              : "YES (Young-Energy-Enthusiasm-Entrepreneurship-Skills) Fest"}
          </p>
          <p className="ongoing-txt mb-5">
            {slice
              ? slice?.body[2]?.items[2].ontext[0].text
              : "CADs (Celebrating Achievement Days"}
          </p>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[0].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[1].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[2].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[3].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[4].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[5].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[6].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[7].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[8].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[9].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[10].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[11].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[12].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[13].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[14].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[15].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[16].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[17].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[18].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[19].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[20].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[21].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[22].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[23].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[24].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[25].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[26].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[27].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[28].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[29].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[30].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[31].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[32].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[33].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[34].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[35].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[36].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[2]?.items[37].onimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="gallery-ongoing-img"
            />
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </div>
  );
};
export default Ongoing;
