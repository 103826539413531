import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Team.css";
const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("The team-saamar");
  const [document] = useAllPrismicDocumentsByType("about_us");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.visionbaner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container lcu mt-5 pt-1">
        <h4 className="esid">
          {slice ? slice?.body[5]?.items[0].teampara[0].text : "President:"}
        </h4>
        <p className="mman">
          {slice
            ? slice?.body[5]?.items[0].teamtitle[0].text
            : "Br. Arshad Mukhtar s/o Late Maulana Mukhtar Ahmed Nadvi, a visionary and scholar, Al Azhar University, Cairo, Egypt"}
        </p>
        <h4 className="esid mt-4">
          {slice
            ? slice?.body[5]?.items[1].teampara[0].text
            : "Managing Committee:"}
        </h4>

        <p className="mman">
          <ol>
            <li>
              {slice
                ? slice?.body[5]?.items[1].teamtitle[0].text
                : "Br. R. Abdul Jaleel - Business man and Visionary for education of children"}
            </li>
            <li>
              {slice
                ? slice?.body[5]?.items[1].teamtitle[1].text
                : "Br. S. Abdul Latheef – Realtor, Engineer and Visionary for education of children"}
            </li>
            <li>
              {slice
                ? slice?.body[5]?.items[1].teamtitle[2].text
                : "Br. R. Abdul Jaleel - Business man and Visionary for education of children"}
            </li>
          </ol>
        </p>
        <h4 className="esid ">
          {slice
            ? slice?.body[5]?.items[2].teampara[0].text
            : "Managing Director:"}
        </h4>
        <p className="mman mt-1">
          {slice
            ? slice?.body[5]?.items[2].teamtitle[0].text
            : "Khalid Musharruf, B.A. Arabic ( University of Darus Salam, Oomerabad, India)"}
        </p>
        <p className="mt-2">
          <span className="wqn">email:</span>
          <span className="mmanaa">
            {slice
              ? slice?.body[5]?.items[2].teamhead[0].text
              : "directorsiis@gmail.com"}
          </span>
        </p>
        <h4 className="esid">
          {slice ? slice?.body[5]?.items[3].teampara[0].text : "Principal:"}
        </h4>
        <p className="mman">
          {slice
            ? slice?.body[5]?.items[3].teamtitle[0].text
            : "Zahrah Fatima, PGCE(University of Sunderland, UK), M.Sc, B.Ed(Bangalore University, India)"}
        </p>
        <p className="mt-2">
          <span className="wqn">email:</span>
          <span className="mmanaa">
            {slice
              ? slice?.body[5]?.items[3].teamhead[0].text
              : "principalsiis@gmail.com"}
          </span>
        </p>
        <h4 className="esid">
          {slice
            ? slice?.body[5]?.items[4].teampara[0].text
            : "Vice Principal:"}
        </h4>
        <p className="mman">
          {slice
            ? slice?.body[5]?.items[4].teamtitle[0].text
            : "Arjumand Banu, B.A, B.Ed (Bangalore University, India)"}
        </p>
        <p className="mt-2">
          <span className="wqn">email:</span>
          <span className="mmanaa">
            {slice
              ? slice?.body[5]?.items[4].teamhead[0].text
              : "officesiis@gmail.com"}
          </span>
        </p>
        <h4 className="esid">
          {slice
            ? slice?.body[5]?.items[5].teampara[0].text
            : "Key Stage 1 co ordinator:"}
        </h4>
        <p className="mman">
          {slice ? slice?.body[5]?.items[5].teamtitle[0].text : "Aiysha Heba"}
        </p>

        <h4 className="esid mt-2" >
          {slice
            ? slice?.body[5]?.items[6].teampara[0].text
            : "Key Stage 2 co Ordinator:"}
        </h4>
        <p className="mman">
          {slice ? slice?.body[5]?.items[6].teamtitle[0].text : "Noorjahan"}
        </p>
        <h4 className="esid mt-2">
          {slice
            ? slice?.body[5]?.items[7].teampara[0].text
            : "Administration:"}
        </h4>
        <p className="mman">
          {slice
            ? slice?.body[5]?.items[7].teamtitle[0].text
            : "A dedicated and efficient team. Details will be updated shortly."}
        </p>
        <p className="mt-1" >
          <span className="wqn">email:</span>
          <span className="mmanaa">
            {slice
              ? slice?.body[5]?.items[7].teamhead[0].text
              : "officesiis@gmail.com"}
          </span>
        </p>
        <h4 className="esid">
          {slice ? slice?.body[5]?.items[8].teampara[0].text : "Teaching:"}
        </h4>
        <p className="mman">
          {slice
            ? slice?.body[5]?.items[8].teamtitle[0].text
            : "A qualified and knowledgeable team. Details will be updated shortly.)"}
        </p>
        <p className="mt-1" >
          <span className="wqn">email:</span>
          <span className="mmanaa">
            {slice
              ? slice?.body[5]?.items[8].teamhead[0].text
              : "officesiis@gmail.com"}
          </span>
        </p>
      </div>
    </div>
  );
};
export default Team;
