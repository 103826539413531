import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Management.css";
const Management = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Management-saamar");
  const [document] = useAllPrismicDocumentsByType("about_us");
  const slice = document && document[0]?.data;
  console.log(slice);

  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.visionbaner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container spt mt-3">
        <h5 className="fwt">
          {slice
            ? slice?.body[2]?.items[0].managetext[0].text
            : "The Roots and Routes:"}
        </h5>
        <p className="dftr">
          {slice
            ? slice?.body[2]?.items[1].managetext[0].text
            : "SAAMAR International Islamic School (SIIS) is run by Jamia Muhammadiyah Education Society (JMES), Mumbai; a charitable and educational society endeavoring for the upliftment of the socially and economically backward students of the muslim community. It was established in 1978 and runs various centers that are located at different places in India."}
        </p>
        <p className="dftr">
          {slice
            ? slice?.body[2]?.items[2].managetext[0].text
            : "The Jamia Muhammadiyah Education Society (JMES) is a leading NGO, whose contributions in the field of education are recognised not only in India but in the whole world. It was the brainchild of Late: Maulana Mukhtar Ahmed Nadvi (Rahimullah), who was a visionary, an eminent scholar and founder President of the society."}
        </p>
        <h5 className="fwt">
          {slice
            ? slice?.body[2]?.items[3].managetext[0].text
            : " SIIS strives to fulfil the following aims and objectives outlined by JMES:"}
        </h5>
        <ul>
          <li className="saamar-manage-li">
            {slice
              ? slice?.body[2]?.items[4].managetext[0].text
              : "Inculcating the true values and virtues of ISLAM as prescribed in the Quran and the Prophetic Sunnah (Tradition/Methodology)"}
          </li>
          <li className="saamar-manage-li">
            {slice
              ? slice?.body[2]?.items[4].managetext[1].text
              : "Perpetuating awareness of the merits of the Indian constitution, culture, harmony, tolerance and progress."}
          </li>
          <li className="saamar-manage-li">
            {slice
              ? slice?.body[2]?.items[4].managetext[2].text
              : "Providing opportunities in education of different subjects on par with modern global standards to every citizen thereby elevating the nation from the clutches of backwardness and illiteracy.              "}
          </li>
          <li className="saamar-manage-li">
            {slice
              ? slice?.body[2]?.items[4].managetext[3].text
              : "Instilling a true sense of service to the human society based on the amalgamation of the knowledge of Islam and contemporary education.              "}
          </li>
          <li className="saamar-manage-li">
            {slice
              ? slice?.body[2]?.items[4].managetext[4].text
              : "Nurturing leadership qualities in students with the sense of accountability, humility, responsibility and justice.              "}
          </li>
          <li className="saamar-manage-li">
            {slice
              ? slice?.body[2]?.items[4].managetext[5].text
              : "Propagating the awareness and importance of maintaining a clean and pure environment, importance of obedience to parents, family values, relationship with neighbours, behaviour with fellow citizens and our duties towards our nation.              "}
          </li>
          <li className="saamar-manage-li">
            {slice
              ? slice?.body[2]?.items[4].managetext[6].text
              : "Training the younger generation about to be good, kind, well-mannered, educate, progressive, qualified and responsible citizens of our nation.              "}
          </li>
        </ul>
        <p className="dftr">
          {slice
            ? slice?.body[2]?.items[5].managetext[0].text
            : "Strong Roots bear Sweet Fruits"}
        </p>
      </div>
    </div>
  );
};
export default Management;
