import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Flyer.css";
const Flyer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Flyer-saamar");
  const [document] = useAllPrismicDocumentsByType("footer");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.banner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-6">
            <img
              src={
                slice
                  ? slice?.body[0]?.items[0].flyerimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
              }
              alt=""
              className="fly-pg-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Flyer;
