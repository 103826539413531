import "./Keydetails.css";
const Parents = (pdata: any) => {
  console.log("navasssss", pdata);
  return (
    <div>
      <div className="container">
        {" "}
        <h4 className="asp">
          {pdata ? pdata?.pdata?.body[4]?.items[0].text[0].text : "THE SCHOOL"}
        </h4>
        <p className="dspp">
          {pdata
            ? pdata?.pdata?.body[4]?.items[1].text[0].text
            : "SAAMAR International Islamic School (SIIS) will:"}
        </p>
        <p className="dspp">
          <ol>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[2].text[0].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[2].text[1].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[2].text[2].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[2].text[3].text
                : "SAAMAR International Islamic 2 (SIIS) will:"}
            </li>

            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[2].text[4].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[2].text[5].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
          </ol>
        </p>
        <p className="dspp">
          {pdata
            ? pdata?.pdata?.body[4]?.items[3].text[0].text
            : "SAAMAR International Islamic School (SIIS) will:"}
        </p>
        <table className="table table-bordered key-sub-table">
          <thead>
            <tr>
              <th>Ideal description of value</th>
            </tr>
          </thead>
          <tbody className="tbt-data">
            <tr>
              <td>
                {pdata
                  ? pdata?.pdata?.body[4]?.items[4].text[0].text
                  : "SAAMAR International Islamic School (SIIS) will:"}
              </td>
            </tr>
            <tr>
              <td>
                {pdata
                  ? pdata?.pdata?.body[4]?.items[5].text[0].text
                  : "SAAMAR International Islamic School (SIIS) will:"}
              </td>
            </tr>
            <tr>
              <td>
                {pdata
                  ? pdata?.pdata?.body[4]?.items[6].text[0].text
                  : "SAAMAR International Islamic School (SIIS) will:"}
              </td>
            </tr>
            <tr>
              <td>
                {pdata
                  ? pdata?.pdata?.body[4]?.items[7].text[0].text
                  : "SAAMAR International Islamic School (SIIS) will:"}
              </td>
            </tr>
            <tr>
              <td>
                {pdata
                  ? pdata?.pdata?.body[4]?.items[8].text[0].text
                  : "SAAMAR International Islamic School (SIIS) will:"}
              </td>
            </tr>
            <tr>
              <td>
                {pdata
                  ? pdata?.pdata?.body[4]?.items[9].text[0].text
                  : "SAAMAR International Islamic School (SIIS) will:"}
              </td>
            </tr>
            <tr>
              <td>
                {pdata
                  ? pdata?.pdata?.body[4]?.items[10].text[0].text
                  : "SAAMAR International Islamic School (SIIS) will:"}
              </td>
            </tr>
          </tbody>
        </table>
        <h4 className="asp mt-2 pt-1">
          {pdata ? pdata?.pdata?.body[4]?.items[11].text[0].text : "THE SCHOOL"}
        </h4>
        <p className="dspp mb-1">
          {pdata
            ? pdata?.pdata?.body[4]?.items[12].text[0].text
            : "SAAMAR International Islamic School (SIIS) will:"}
        </p>
        <p className="dspp">
          <ol>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[0].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[2].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[3].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[4].text
                : "SAAMAR International Islamic 2 (SIIS) will:"}
            </li>

            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[5].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[6].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[7].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[8].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[9].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[9].text
                : "SAAMAR International Islamic 2 (SIIS) will:"}
            </li>

            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[9].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[10].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[11].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[12].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[13].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[14].text
                : "SAAMAR International Islamic 2 (SIIS) will:"}
            </li>

            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[15].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[16].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[17].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[18].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[19].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[20].text
                : "SAAMAR International Islamic 2 (SIIS) will:"}
            </li>

            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[21].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[22].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[23].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[24].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {pdata
                ? pdata?.pdata?.body[4]?.items[13].text[25].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
          </ol>
        </p>
      </div>
    </div>
  );
};
export default Parents;
