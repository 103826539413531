import "./Form.css";
function Form() {
  return (
    <div className="container olp">
      <form action="#" className="contact-form">
        <div className="row">
          <div className="col-md-4 mt-4">
            {" "}
            <input
              type="text"
              className="form-control fees"
              placeholder="Enter Your Name"
            ></input>
          </div>
          <div className="col-md-4 mt-4">
            {" "}
            <input
              type="text"
              className="form-control fees"
              placeholder="Enter Your Email"
            ></input>
          </div>
          <div className="col-md-4 mt-4">
            {" "}
            <input
              type="text"
              className="form-control fees"
              placeholder="Enter your Subject"
            ></input>
          </div>
        </div>
        <div className="olp  mt-3">
          <textarea className="text-area" placeholder="Enter your Message" />
        </div>
        <br />
        <input type="submit" value="submit" className="btn-fld-sma"></input>
      </form>
    </div>
  );
}

export default Form;
