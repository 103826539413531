import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Teaching.css";
const Teaching = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Teaching Pedagogy - saamar");
  const [document] = useAllPrismicDocumentsByType("curriculum");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.sybanner.url} alt="" className="sgi" />
      <div className="container std mt-3">
        <div className="row">
          <div className="col-md-6">
            <h4 className="Tech">{slice?.body[2]?.items[0].petext[0].text}</h4>
            <h4 className="Tech">{slice?.body[2]?.items[1].petext[0].text}</h4>
            <p className="tech-para">
              {slice?.body[2]?.items[2].petext[0].text}
            </p>
            <p className="tech-para">
              {slice?.body[2]?.items[3].petext[0].text}
            </p>
            <p className="tech-para">This philosophy is always kept in mind:</p>
          </div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[2]?.items[0].peimg.url}
              alt=""
              className="teach-ped-img"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img
              src={slice?.body[2]?.items[1].peimg.url}
              alt=""
              className="pdg"
            />
          </div>
          <div className="col-md-6">
            <img
              src={slice?.body[2]?.items[2].peimg.url}
              alt=""
              className="pdg"
            />
          </div>
        </div>
        <h4 className="Tech pt-3 mt-2">
          {slice?.body[2]?.items[4].petext[0].text}
        </h4>
        <p className="tech-para">{slice?.body[2]?.items[5].petext[0].text}</p>
        <div className="pdg1 mt-2 pt-3">
          <img
            src={slice?.body[2]?.items[3].peimg.url}
            alt=""
            className="tri-angle"
          />
        </div>
        <p className="tech-para mt-2 py-3">
          {slice?.body[2]?.items[6].petext[0].text}
        </p>
        <div className="pdg1">
          <img
            src={slice?.body[2]?.items[4].peimg.url}
            alt=""
            className="tri-angle"
          />
        </div>
        <p className="tech-para pt-3">
          {slice?.body[2]?.items[7].petext[0].text}
        </p>
        <ul>
          <li>
            {" "}
            <p className="tech-para">
              {slice?.body[2]?.items[8].petext[0].text}
            </p>
          </li>
          <li>
            {" "}
            <p className="tech-para">
              {slice?.body[2]?.items[8].petext[1].text}
            </p>
          </li>
          <li>
            {" "}
            <p className="tech-para">
              {slice?.body[2]?.items[8].petext[2].text}
            </p>
          </li>
          <li>
            {" "}
            <p className="tech-para">
              {slice?.body[2]?.items[8].petext[3].text}
            </p>
          </li>
          <li>
            {" "}
            <p className="tech-para">
              {slice?.body[2]?.items[8].petext[4].text}
            </p>
          </li>
          <li>
            {" "}
            <p className="tech-para">
              {slice?.body[2]?.items[8].petext[5].text}
            </p>
          </li>
          <li>
            {" "}
            <p className="tech-para">
              {slice?.body[2]?.items[8].petext[6].text}
            </p>
          </li>
        </ul>
        <p className="tech-para">{slice?.body[2]?.items[9].petext[0].text}</p>
        <p className="tech-para">{slice?.body[2]?.items[10].petext[0].text}</p>
        <p className="tech-para">{slice?.body[2]?.items[11].petext[0].text}</p>
      </div>
    </div>
  );
};
export default Teaching;
