import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Curricular.css";
const Curricular = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Extra Curricular Activities - saamar");
  const [document] = useAllPrismicDocumentsByType("curriculum");
  const slice = document && document[0]?.data;
  console.log(slice);

  return (
    <div className="">
      <img src={slice?.body[0]?.primary.sybanner.url} alt="" className="sgi" />
      <div className="container pt-5">
        <p className="culum-extr-p">{slice?.body[3]?.primary.epara[0].text}</p>
        <div className="row">
          <div className="col-md-4">
            <p className="pst">
              {slice?.body[3]?.items[0].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[0].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[1].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[1].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[2].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[2].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p className="pst">
              {slice?.body[3]?.items[3].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[3].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[4].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[4].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[5].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[5].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p className="pst">
              {slice?.body[3]?.items[6].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[6].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[7].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[7].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[8].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[8].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p className="pst">
              {slice?.body[3]?.items[9].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[9].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[10].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[10].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[11].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[11].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p className="pst">
              {slice?.body[3]?.items[12].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[12].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[13].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[13].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[14].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[14].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p className="pst">
              {slice?.body[3]?.items[15].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[15].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[16].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[16].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
          <div className="col-md-4">
            {" "}
            <p className="pst">
              {slice?.body[3]?.items[17].etext[0].text}{" "}
              <img
                src={slice?.body[3]?.items[17].eimg.url}
                alt=""
                className="extra-head-img"
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p className="pst">{slice?.body[4]?.primary.title[0].text} </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[0].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[0].xpara[0].text}{" "}
            </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[1].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[1].xpara[0].text}{" "}
            </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[2].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[2].xpara[0].text}{" "}
            </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[3].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[3].xpara[0].text}{" "}
            </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[4].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[4].xpara[0].text}{" "}
            </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[5].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[5].xpara[0].text}{" "}
            </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[6].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[6].xpara[0].text}{" "}
            </p>
            <p className="pst cpst">
              {slice?.body[4]?.items[7].xtitle[0].text}{" "}
            </p>
            <p className="culum-extr-p">
              {slice?.body[4]?.items[7].xpara[0].text}{" "}
            </p>
          </div>
          <div className="col-md-6">
            <img
              src={slice?.body[4]?.items[0].ximg.url}
              alt=""
              className="extra-head-img1"
            />
            <img
              src={slice?.body[4]?.items[1].ximg.url}
              alt=""
              className="extra-head-img1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Curricular;
