import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Annualevents.css";
const Annualevents = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Annual events - saamar");
  const [document] = useAllPrismicDocumentsByType("gallery");
  const slice = document && document[0]?.data;
  console.log(slice);
  const normalData = [
    {
      src: "/saamarimages/ae1.png",
    },
    {
      src: "/saamarimages/ae2.png",
    },
    {
      src: "/saamarimages/ae3.jfif",
    },
    {
      src: "/saamarimages/ae3.png",
    },
    {
      src: "/saamarimages/ae4.png",
    },
    {
      src: "/saamarimages/ae5.png",
    },
    {
      src: "/saamarimages/ae6.jfif",
    },
    {
      src: "/saamarimages/ae7.jfif",
    },
    {
      src: "/saamarimages/ae8.jfif",
    },
    {
      src: "/saamarimages/ae9.jfif",
    },
    {
      src: "/saamarimages/ae10.jfif",
    },
    {
      src: "/saamarimages/ae11.jfif",
    },
    {
      src: "/saamarimages/ae12.jfif",
    },
    {
      src: "/saamarimages/ae14.jfif",
    },
    {
      src: "/saamarimages/ae15.jfif",
    },
    {
      src: "/saamarimages/ae16.jfif",
    },
    {
      src: "/saamarimages/ae17.jfif",
    },
    {
      src: "/saamarimages/ae18.jfif",
    },
    {
      src: "/saamarimages/ae19.jfif",
    },
    {
      src: "/saamarimages/ae20.jfif",
    },
    {
      src: "/saamarimages/ae21.jfif",
    },
    {
      src: "/saamarimages/ae22.jfif",
    },
    {
      src: "/saamarimages/ae23.jfif",
    },
    {
      src: "/saamarimages/ae24.jfif",
    },
    {
      src: "/saamarimages/ae25.jfif",
    },
    {
      src: "/saamarimages/ae26.jfif",
    },
    {
      src: "/saamarimages/ae27.jfif",
    },
    {
      src: "/saamarimages/ae28.jfif",
    },
    {
      src: "/saamarimages/ae29.jfif",
    },
    {
      src: "/saamarimages/ae30.jfif",
    },
    {
      src: "/saamarimages/ae31.jfif",
    },
    {
      src: "/saamarimages/ae32.jfif",
    },
    {
      src: "/saamarimages/ae33.jfif",
    },
    {
      src: "/saamarimages/ae34.jfif",
    },
  ];
  const title = [
    {
      text: "KAS (Knowledge-Application-Skills) Fest",
    },
    {
      text: "YES (Young-Energy-Enthusiasm-Entrepreneurship-Skills) Fest",
    },
    {
      text: "CADs (Celebrating Achievement Days)",
    },
  ];
  const Normaldatatext = title.map((item: any, i: any) => {
    return <p className="annual-txt">{item.text}</p>;
  });
  const Prismicdatatext = slice?.body[1]?.items?.map((item: any, i: any) => {
    return <p className="annual-txt">{item.aetext[0]?.text}</p>;
  });

  const NormalDataimg = normalData.map((item: any, i: any) => {
    return (
      <div className="col-md-4" key={i}>
        <img src={item.src} className="gallery-annual-img" />
      </div>
    );
  });
  const PrismicData = slice?.body[1]?.items?.map((item: any, i: any) => {
    console.log(item.aetext[0]?.text);
    return (
      <>
        <div className="col-md-4" key={i}>
          <img src={item.aeimg.url} className="gallery-annual-img" />
        </div>
      </>
    );
  });
  return (
    <div>
      {" "}
      <img
        src={
          slice
            ? slice?.body[0]?.primary.bannerimg.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi "
      />
      <div className="container pt-4 mt-4">
        <p className="mb-4 pb-2">{slice ? Prismicdatatext : Normaldatatext}</p>
        <div className="row itm">{slice ? PrismicData : NormalDataimg}</div>
      </div>
    </div>
  );
};
export default Annualevents;
