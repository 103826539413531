import "./Service.css";
const Service = (scontent: any) => {
  return (
    <div className="bgser">
      <div className="container">
        <h3 className="service-head mb-5">
          <span>
            {scontent &&
              scontent?.scontent?.body[2]?.primary.servicetitle1[0].text}
          </span>{" "}
          <span className="svi">
            {scontent &&
              scontent?.scontent?.body[2]?.primary.servicetitle2[0].text}
          </span>
        </h3>
        <div className="row dero" data-aos="fade-right">
          <div className="col-md-4">
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-bus"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {scontent?.scontent?.body[2]?.items[0].servicehead[0].text}
                </h4>
                <p className="cfr">
                  {scontent?.scontent?.body[2]?.items[0].servicepara[0].text}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-book"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[1].servicehead[0].text
                    : "FACULTY"}
                </h4>
                <p className="cfr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[1].servicepara[0].text
                    : "Caring, Warm, Knowledgeable and dedicated"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row tru">
              <div className="col-md-2 fz">
                <i className="fa fa-building"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {" "}
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[2].servicehead[0].text
                    : "ICLASSROOMS"}
                </h4>
                <p className="cfr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[2].servicepara[0].text
                    : " Spacious, Ventilated and Conducive for Learning"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row dero" data-aos="fade-right">
          <div className="col-md-4">
            {" "}
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-users"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {scontent?.scontent?.body[2]?.items[3].servicehead[0].text}
                </h4>
                <p className="cfr">
                  {scontent?.scontent?.body[2]?.items[3].servicepara[0].text}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-refresh"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[4].servicehead[0].text
                    : "REFRESHMENT"}
                </h4>
                <p className="cfr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[4].servicepara[0].text
                    : "Warm, healthful, hygienically prepared. Optional for students.Based on needs."}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-external-link"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[5].servicehead[0].text
                    : "AFFILIATION"}
                </h4>
                <p className="cfr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[5].servicepara[0].text
                    : " Affiliated to the University of Cambridge, Cambridge Assessment International Education, UK Centre Number: IN362"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row dero" data-aos="fade-right">
          <div className="col-md-4">
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-map-marker"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {scontent?.scontent?.body[2]?.items[6].servicehead[0].text}
                </h4>
                <p className="cfr">
                  {scontent?.scontent?.body[2]?.items[6].servicepara[0].text}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-home"></i>
              </div>
              <div className="col-md-8">
                <h4 className="gtr">
                  {" "}
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[7].servicehead[0].text
                    : "INFRASTRUCTURE"}
                </h4>
                <p className="cfr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[7].servicepara[0].text
                    : "Two and a half acres of land, Playgrounds, Play areas, Wash area, Classrooms, Laboratories, Lavatories, Libraries, Audio-Visual room, Educational Resource Centre, etc"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row tru">
              <div className="col-md-2">
                <i className="fa fa-clock-o"></i>
              </div>
              <div className="col-md-10">
                <h4 className="gtr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[8].servicehead[0].text
                    : "ACTIVITIES"}
                </h4>
                <p className="cfr">
                  {scontent
                    ? scontent?.scontent?.body[2]?.items[8].servicepara[0].text
                    : "Based on VAKOG(Visual-Auditory-Kinesthetic-Olfactory-Gustatory). Time for togetherness is a regular concept at the school where children come together for any /all of these learning activities."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Service;
