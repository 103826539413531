import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Website.css";
const Wensite = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("To Website Visitors-saamar");
  const [document] = useAllPrismicDocumentsByType("information");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      {" "}
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-4 mt-4">
        <p className="web-txt">{slice?.body[4]?.items[0].informtext[0].text}</p>
        <p className="web-txt">{slice?.body[4]?.items[0].informtext[1].text}</p>
        <p className="web-txt">
          <span>{slice?.body[4]?.items[0].informtext[2].text}</span>
          <span className="web-email">
            {slice?.body[4]?.items[0].informtext[3].text}
          </span>
        </p>
        <p className="web-txt">{slice?.body[4]?.items[0].informtext[4].text}</p>
        <p className="web-txt">{slice?.body[4]?.items[0].informtext[5].text}</p>
      </div>
    </div>
  );
};
export default Wensite;
