import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Student.css";
const Student = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("To Students-saamar");
  const [document] = useAllPrismicDocumentsByType("information");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-5 mt-3" style={{ textAlign: "justify" }}>
        {" "}
        <h4 className="stu-head">{slice?.body[1]?.primary.text[0].text}:</h4>
        <ol>
          <li className="stu-text">{slice?.body[1]?.items[0].list[0].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[1].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[2].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[3].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[4].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[5].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[6].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[7].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[8].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[9].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[10].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[11].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[12].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[13].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[14].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[15].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[16].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[17].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[18].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[19].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[20].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[21].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[22].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[23].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[24].text}</li>
          <li className="stu-text">{slice?.body[1]?.items[0].list[25].text}</li>
        </ol>
        <p className="" style={{ fontWeight: "bold", lineHeight: "26px" }}>
          {slice?.body[1]?.items[1].list[0].text}:
        </p>
        <div className="table-responsive">
          <table className="table table-bordered student-table table-sm">
            {" "}
            <thead>
              {" "}
              <tr>
                {" "}
                <th>Stage</th> <th>Key stage 1(Mont-Grade2)</th>{" "}
                <th>Key stage 2 (Gr 3-6)</th> <th>Key stage 3 (Gr 7-10)</th>{" "}
              </tr>{" "}
            </thead>{" "}
            <tbody className="stu-table-data">
              {" "}
              <tr>
                {" "}
                <td>Letters</td>{" "}
                <td>
                  {" "}
                  <table className="table table-bordered">
                    {" "}
                    <tbody className="stu-table-data">
                      <tr>
                        <td>A</td> <td>B</td> <td>C</td> <td>D</td> <td>G</td>{" "}
                        <td>P</td> <td>Q</td> <td>R</td> <td>S</td>{" "}
                      </tr>
                    </tbody>
                  </table>{" "}
                </td>{" "}
                <td>
                  {" "}
                  <table className="table table-bordered">
                    {" "}
                    <tbody className="stu-table-data">
                      <tr>
                        <td>I</td> <td>J</td> <td>K</td> <td>L</td> <td>M</td>{" "}
                        <td>S</td> <td>U</td> <td>Z</td>{" "}
                      </tr>
                    </tbody>
                  </table>{" "}
                </td>{" "}
                <td>
                  {" "}
                  <table className="table table-bordered">
                    {" "}
                    <tbody className="stu-table-data">
                      <tr>
                        <td>E</td> <td>F</td> <td>H</td> <td>N</td> <td>O</td>{" "}
                        <td>T</td> <td>V</td> <td>W</td> <td>Y</td>{" "}
                      </tr>
                    </tbody>
                  </table>{" "}
                </td>{" "}
              </tr>{" "}
            </tbody>{" "}
          </table>
        </div>
        <div className="row py-2 pb-4">
          <div className="col-md-3"></div>
          <div className="col-md-6 col-sm-6 col-xs-12 info-stnd-col">
            <p>
              “You are the best nation produced for mankind. You enjoin what is
              right and forbid what is wrong and believe in Allah”
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <img
              src={slice?.body[1]?.items[0].stuimg.url}
              alt=""
              className="stu-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={slice?.body[1]?.items[1].stuimg.url}
              alt=""
              className="stu-img"
            />
          </div>
          <div className="col-md-4">
            <img
              src={slice?.body[1]?.items[2].stuimg.url}
              alt=""
              className="stu-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Student;
