import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Learning.css";
const Learning = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Teaching and Learning - saamar");
  const [document] = useAllPrismicDocumentsByType("curriculum");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      {" "}
      <img src={slice?.body[0]?.primary.sybanner.url} alt="" className="sgi" />
      <div className="container pt-5 mt-1">
        <h4 className="learn-head">{slice?.body[1]?.items[0].lpara[0].text}</h4>
        <p className="learn-text">{slice?.body[1]?.items[0].ltext[0].text}</p>
        <p className="learn-text-fr">
          {slice?.body[1]?.items[1].lpara[0].text}
        </p>
        <p className="learn-text-fr">
          {slice?.body[1]?.items[1].ltext[0].text}
        </p>
        <p className="learn-text-fr">
          {slice?.body[1]?.items[2].lpara[0].text}
        </p>
        <p className="learn-text-fr">
          {slice?.body[1]?.items[2].ltext[0].text}
        </p>
        <p className="learn-text-fr">
          {slice?.body[1]?.items[3].lpara[0].text}
        </p>
        <p className="learn-text-fr">
          {slice?.body[1]?.items[3].ltext[0].text}
        </p>
        <p className="learn-text-fr">
          {slice?.body[1]?.items[4].lpara[0].text}
        </p>
        <h4 className="learn-head">{slice?.body[1]?.items[5].lpara[0].text}</h4>
        <div className="container">
          <h4 className="learn-head">
            {slice?.body[1]?.items[6].lpara[0].text}
          </h4>
          <p className="learn-text">{slice?.body[1]?.items[6].ltext[0].text}</p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[7].lpara[0].text}
          </h4>
          <p className="learn-text">{slice?.body[1]?.items[7].ltext[0].text}</p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[8].lpara[0].text}
          </h4>
          <p className="learn-text">{slice?.body[1]?.items[8].ltext[0].text}</p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[9].lpara[0].text}
          </h4>
          <p className="learn-text">{slice?.body[1]?.items[9].ltext[0].text}</p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[10].lpara[0].text}
          </h4>
          <p className="learn-text">
            {slice?.body[1]?.items[10].ltext[0].text}
          </p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[11].lpara[0].text}
          </h4>
          <p className="learn-text">
            {slice?.body[1]?.items[11].ltext[0].text}
          </p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[12].lpara[0].text}
          </h4>
          <p className="learn-text">
            {slice?.body[1]?.items[12].ltext[0].text}
          </p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[13].lpara[0].text}
          </h4>
          <p className="learn-text">
            {slice?.body[1]?.items[13].ltext[0].text}
          </p>
          <h4 className="learn-head">
            {slice?.body[1]?.items[14].lpara[0].text}
          </h4>
          <p className="learn-text">
            {slice?.body[1]?.items[14].ltext[0].text}
          </p>
          <p className="learn-head">
            {slice?.body[1]?.items[15].lpara[0].text}
          </p>

          <div className="row">
            <div className="col-md-6">
              {" "}
              <h4 className="learn-head">
                {slice?.body[1]?.items[6].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[6].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[7].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[7].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[8].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[8].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[9].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[9].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[10].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[10].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[11].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[11].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[12].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[12].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[13].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[13].ltext[0].text}
              </p>
              <h4 className="learn-head">
                {slice?.body[1]?.items[14].lpara[0].text}
              </h4>
              <p className="learn-text">
                {slice?.body[1]?.items[14].ltext[0].text}
              </p>
            </div>
            <div className="col-md-6" style={{ textAlign: "center" }}>
              <img src={slice?.body[1]?.primary.limg.url} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Learning;
