import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { Carousel } from "react-bootstrap";
import "./check.css";
const Vivo = () => {
  const [document] = useAllPrismicDocumentsByType("home_type");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div className="test-bg">
      <div className="container py-5">
        <h3 className="test-head mb-5">Testimonials</h3>
        <Carousel>
          <Carousel.Item>
            <p className="test-para">
              {slice?.body[4]?.items[0].testipara[0].text}
              <br />
              {slice?.body[4]?.items[0].testipara[1].text}
              <br />
              {slice?.body[4]?.items[0].testipara[2].text}
              <br />
              {slice?.body[4]?.items[0].testipara[3].text}
              <br />
              {slice?.body[4]?.items[0].testipara[4].text}
              <br />
            </p>
            <div className="profile-image">
              {" "}
              <img src="http://www.saamar.com/image/journal/article?img_id=2526998&t=1596727065437" />{" "}
              <p className="test-p">Testimony 1</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <p className="test-para">
              {slice?.body[4]?.items[1].testipara[0].text}
              <br />
              {slice?.body[4]?.items[1].testipara[1].text}
              <br />
              {slice?.body[4]?.items[1].testipara[2].text}
              <br />
              {slice?.body[4]?.items[1].testipara[3].text}
              <br />
              {slice?.body[4]?.items[1].testipara[4].text}
              <br />
              {slice?.body[4]?.items[1].testipara[5].text}
              <br />
            </p>
            <div className="profile-image">
              {" "}
              <img src="http://www.saamar.com/image/journal/article?img_id=2526998&t=1596727065437" />{" "}
              <p className="test-p">Testimony2</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <p className="test-para">
              {slice?.body[4]?.items[2].testipara[0].text}
              <br />
              {slice?.body[4]?.items[2].testipara[1].text}
              <br />
              {slice?.body[4]?.items[2].testipara[2].text}
              <br />
            </p>
            <div className="profile-image">
              {" "}
              <img src="http://www.saamar.com/image/journal/article?img_id=2526998&t=1596727065437" />{" "}
              <p className="test-p"> Testimony3</p>{" "}
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <p className="test-para">
              {slice?.body[4]?.items[3].testipara[0].text}
            </p>
            <div className="profile-image">
              {" "}
              <img src="http://www.saamar.com/image/journal/article?img_id=2526998&t=1596727065437" />{" "}
              <p className="test-p"> Testimony4</p>{" "}
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
export default Vivo;
