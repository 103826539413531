import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Event.css";
const Event = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Ongoing events - saamar");
  const [document] = useAllPrismicDocumentsByType("notice_board");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-5 mt-2">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-5">
            <p className="notice-event-p">
              {slice?.body[1]?.items[0].para[0].text}
            </p>
            <p className="notice-event-p">
              {slice?.body[1]?.items[1].para[0].text}
            </p>
            <p className="notice-event-p">
              {" "}
              {slice?.body[1]?.items[2].para[0].text}
            </p>
            <p className="notice-event-p">
              {slice?.body[1]?.items[3].para[0].text}
            </p>
            <p className="notice-event-p">
              {slice?.body[1]?.items[4].para[0].text}
            </p>
            <p className="notice-event-p">
              {" "}
              {slice?.body[1]?.items[5].para[0].text}
            </p>
            <p className="notice-event-p">
              {slice?.body[1]?.items[6].para[0].text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Event;
