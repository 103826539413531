import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./Freq.css";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

const Freq = (fcontent: any) => {
  return (
    <div className="ngt">
      <div className="container pb-5">
        <h2 className="freq-head pt-5 pb-4 mb-2">
          <span>Frequently Asked</span> <span className="svi">Questions</span>
        </h2>
        <div className="row">
          <div className="col-md-6">
            <Accordion allowZeroExpanded>
              {fcontent?.fcontent?.body[5]?.items.map((item: any) => (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="row px-2">
                        <div className="col-md-11">{item.qatext[0].text}</div>
                        <div className="col-md-1">
                          <i className="fa fa-arrow-down faq1-pt-down"></i>
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-start">
                        <div className="flex-grow-1">{item.qatext[0].text}</div>
                        <div className="">
                          <i className="fa fa-arrow-down faq1-pt-down"></i>
                        </div>
                      </div> */}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <div className="card-body">
                    {item?.qapara?.map((ans: any) => (
                      <AccordionItemPanel style={{ border: "0" }}>
                        {ans.text}
                      </AccordionItemPanel>
                    ))}
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          <div className="col-md-6">
            <Accordion allowZeroExpanded>
              {fcontent?.fcontent?.body[5]?.items.map((item: any) => (
                <AccordionItem key={item.uuid}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="row px-2">
                        <div className="col-md-11"> {item.qtext[0].text}</div>
                        <div className="col-md-1">
                          <i className="fa fa-arrow-down faq1-pt-down"></i>
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <div className="card-body">
                    {item?.qpara?.map((ans: any) => (
                      <AccordionItemPanel style={{ border: "0" }}>
                        {ans.text}
                      </AccordionItemPanel>
                    ))}
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Freq;
