import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { Title } from "../Title";
import "./Facilities.css";
const Facilities = () => {
  Title("Facilities-saamar");
  const [document] = useAllPrismicDocumentsByType("infrastructure");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.ebanner.url} alt="" className="sgi" />
      <div className="container pt-4 mt-4">
        <ul>
          <li className="sit">{slice?.body[1]?.items[0].ftext[0].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{textAlign:'center'}}>
            <img
              src={slice?.body[1]?.items[0].fimg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[1]?.items[0].ftext[1].text}</li>
        </ul>
        <ul>
          <li className="sit">{slice?.body[1]?.items[0].ftext[2].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{textAlign:'center'}}>
            <img
              src={slice?.body[1]?.items[1].fimg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[1]?.items[0].ftext[3].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[4].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[5].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[6].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[7].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[8].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[9].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[10].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[11].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[12].text}</li>
          <li className="sit">{slice?.body[1]?.items[0].ftext[13].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{textAlign:'center'}}>
            <img
              src={slice?.body[1]?.items[2].fimg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        
      </div>
    </div>
  );
};
export default Facilities;
