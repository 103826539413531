import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Candidate.css";
const Candidate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title('To Candidates for Recruitment - saamar')
  const [document] = useAllPrismicDocumentsByType("information");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <div>
        {" "}
        <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
        <div className="container pt-4 mt-5">
          <h4 className="candi-head">{slice?.body[5]?.items[0].candihead[0].text}</h4>
          <p className="candi-text">{slice?.body[5]?.items[0].candtext[0].text}</p>
          <p className="candi-text">{slice?.body[5]?.items[0].candtext[1].text}</p>
          <p className="candi-text">{slice?.body[5]?.items[0].candtext[2].text}</p>
          <p className="candi-text">{slice?.body[5]?.items[0].candtext[3].text}</p>
          <p className="candi-text">{slice?.body[5]?.items[0].candtext[4].text}</p>
          <p className="candi-text" style={{ fontWeight: "bold", color:'black'}}>
            {slice?.body[5]?.items[0].candtext[5].text}
          </p>
          <div className="row">
            <div className="col-md-2">
              <img
                src={slice?.body[5]?.items[1].candiimg.url}
                alt=""
                className=""
              />
            </div>
            <div className="col-md-10">
              <h4 className="candi-head">
                {slice?.body[5]?.items[1].candihead[0].text}
              </h4>
            </div>
            <p className="candi-text">{slice?.body[5]?.items[1].candtext[0].text}</p>
            <p className="candi-text">
              <span>{slice?.body[5]?.items[1].candtext[1].text}</span>
              <span className="candi-text1">
                {slice?.body[5]?.items[1].candtext[2].text}
              </span>
            </p>
            <p className="candi-text">
              <span>{slice?.body[5]?.items[1].candtext[3].text}</span>
              <span className="candi-text1">
                {slice?.body[5]?.items[1].candtext[2].text}
              </span>
            </p>
            <p className="candi-text">{slice?.body[5]?.items[1].candtext[4].text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Candidate;
