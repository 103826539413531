import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./School.css";
const School = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title('To School Visitors-saamar');
  const [document] = useAllPrismicDocumentsByType("information");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container py-4 mt-4">
        <p className="sch-txt">{slice?.body[3]?.primary.text[0].text}</p>
        <p className="sch-txt">{slice?.body[3]?.items[0].visittext[0].text}</p>
        <ol>
          <li>
            <p className="sch-txt">
              {slice?.body[3]?.items[0].visittext[1].text}
            </p>
          </li>
          <li>
            <p className="sch-txt">
              {slice?.body[3]?.items[0].visittext[2].text}
            </p>
          </li>
          <li>
            <p className="sch-txt">
              {slice?.body[3]?.items[0].visittext[3].text}
            </p>
          </li>
          <li>
            <p className="sch-txt">
              {slice?.body[3]?.items[0].visittext[4].text}
            </p>
          </li>
          <li>
            <p className="sch-txt">
              {slice?.body[3]?.items[0].visittext[5].text}
            </p>
          </li>
        </ol>
        <h4 className="sch-head">
          {slice?.body[3]?.items[1].visittext[0].text}
        </h4>

        <div className="row">
          <div className="col-md-6">
            <img
              src={slice?.body[3]?.items[0].visitimage.url}
              alt=""
              className="sch-image"
            />
          </div>
          <div className="col-md-6">
            <img
              src={slice?.body[3]?.items[1].visitimage.url}
              alt=""
              className="sch-image sch-g"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default School;
