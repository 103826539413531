import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Vision-mission-principles.css";
const Vision = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Vision-Mission-Principles - saamar");
  const [document] = useAllPrismicDocumentsByType("about_us");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.visionbaner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container pt-5">
        <h4 className="vti">
          {slice
            ? slice?.body[0]?.items[0].visiontext[0].text
            : "Vision and Mission:"}
        </h4>
        <h4 className="vti">
          {slice ? slice?.body[0]?.items[1].visiontext[0].text : "Vision:"}
        </h4>
        <p className="pqe mb-4">
          {slice
            ? slice?.body[0]?.items[2].visiontext[0].text
            : " To provide quality education in accordance with International standards with universal values of Islam (The Arabic word for peace acquired through submission to The Almighty)."}
        </p>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <img
              src={
                slice
                  ? slice?.body[0]?.items[0].visionimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527022&t=1596730284173"
              }
              alt=""
              className=""
              style={{ width: "100%", height: "150px" }}
            />
          </div>

          <div className="col-md-4"></div>
        </div>
        <h4 className="vti mt-4">
          {slice ? slice?.body[0]?.items[3].visiontext[0].text : "Mission:"}
        </h4>
        <p className="pqe mb-4">
          {slice
            ? slice?.body[0]?.items[4].visiontext[0].text
            : "Promotes moral and academic excellence. Challenge and support learners in an environment where safety, warmth and trust are key values."}
        </p>
        <b className="kvs">
          {slice ? slice?.body[0]?.items[5].visiontext[0].text : "KEY VALUES"}
        </b>
        <ul className="li-ul vm-ul">
          <li>
            {slice ? slice?.body[0]?.items[6].visiontext[0].text : "Safety"}
          </li>{" "}
          <li>
            {slice ? slice?.body[0]?.items[6].visiontext[1].text : "Warmth"}
          </li>{" "}
          <li>
            {slice ? slice?.body[0]?.items[6].visiontext[0].text : "Trust"}
          </li>{" "}
        </ul>
        <h4 className="vti mb-3">Principles:</h4>
        <div className="row">
          <div className="col-md-3">
            <p>
              <b className="kvs mb-4">
                {" "}
                {slice
                  ? slice?.body[0]?.items[7].visiontext[0].text
                  : "High expectations"}
              </b>
            </p>

            <img
              src={
                slice
                  ? slice?.body[0]?.items[8].visionimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527023&t=1596730284281"
              }
              alt=""
              className="ion"
            />
          </div>
          <div className="col-md-3">
            <p>
              <b className="kvs mb-4"> Team work</b>
            </p>
            <img
              src={
                slice
                  ? slice?.body[0]?.items[9].visionimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527024&t=1596730284365"
              }
              alt=""
              className="ion"
            />
          </div>
          <div className="col-md-3">
            <p>
              {" "}
              <b className="kvs mb-4">
                {slice
                  ? slice?.body[0]?.items[10].visiontext[0].text
                  : "Mutual respect"}
              </b>
            </p>
            <img
              src={
                slice
                  ? slice?.body[0]?.items[10].visionimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527025&t=1596730284478"
              }
              alt=""
              className="ion"
            />
          </div>
          <div className="col-md-3">
            <p>
              <b className="kvs mb-4">
                {slice
                  ? slice?.body[0]?.items[11].visiontext[0].text
                  : "Support and understanding"}
              </b>
            </p>
            <img
              src={
                slice
                  ? slice?.body[0]?.items[11].visionimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527026&t=1596730284585"
              }
              alt=""
              className="ion"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Vision;
