import "./Pics.css";

const Pics = (pcontent: any) => {
  return (
    <div className="">
      <h3 className="sarm my-5">
        <span>
          {pcontent
            ? pcontent?.pcontent?.body[3]?.primary.ftext1[0].text
            : "Featured"}
        </span>
        <span className="svi">
          {pcontent
            ? pcontent?.pcontent?.body[3]?.primary.ftext2[0].text
            : "Work"}
        </span>
      </h3>
      <div className="row">
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[0].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527360&t=1596788465500"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[0].ftext[0].text
                  : "RECOGNITION"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[1].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527361&t=1596788466743"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[1].ftext[0].text
                  : "OLYMPIADS"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[2].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527362&t=1596788468559"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[2].ftext[0].text
                  : "INTER SCHOOL SPORTS"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[3].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527363&t=1596788469684"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[3].ftext[0].text
                  : "INTER SCHOOL COMPETITIONS"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[4].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527364&t=1596788471183"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[4].ftext[0].text
                  : "AWARDS AND ACHIEVEMENTS"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[5].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527365&t=1596788472761"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[5].ftext[0].text
                  : "ANNUAL FESTS"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[6].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527366&t=1596788473763"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[6].ftext[0].text
                  : "CO CURRICULAR ACTIVITIES"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 px-0">
          <div className="getr">
            <img
              src={
                pcontent
                  ? pcontent?.pcontent?.body[3]?.items[7].fimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2527367&t=1596788474911"
              }
              alt=""
              className="scim"
            />
            <div className="overlay">
              <div className="text">
                {pcontent
                  ? pcontent?.pcontent?.body[3]?.items[7].ftext[0].text
                  : "CELEBRATING ACHIEVEMENT"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pics;
