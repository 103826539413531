import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Title } from "../Title";
import "./Other.css";
const Other = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Other - saamar");
  const [document] = useAllPrismicDocumentsByType("gallery");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <div>
        <img
          src={
            slice
              ? slice?.body[0]?.primary.bannerimg.url
              : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
          }
          alt=""
          className="sgi"
        />
        <div className="container pt-5 mt-2">
          <p className="other-txt">
            {slice
              ? slice?.body[3]?.items[0].othead[0].text
              : "POPs (Parent Orientation Programmes) and Parent Teacher Meetings            "}
          </p>
          <div className="row">
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[0].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[1].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[2].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[3].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <div className="container">
          <p className="other-txt">
            {slice
              ? slice?.body[3]?.items[1].othead[0].text
              : "POPs (Parent Orientation Programmes) and Parent Teacher Meetings            "}
          </p>
          <div className="row">
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[4].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[5].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[6].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[7].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[8].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[9].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <p className="other-txt">
            {slice
              ? slice?.body[3]?.items[2].othead[0].text
              : "POPs (Parent Orientation Programmes) and Parent Teacher Meetings            "}
          </p>
          <div className="row">
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[10].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[11].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[12].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <img
                src={
                  slice
                    ? slice?.body[3]?.items[13].otimg.url
                    : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
                }
                alt=""
                className="gallery-other-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Other;
