import { useAllPrismicDocumentsByType } from "@prismicio/react";
import Aos from "aos";
import { useEffect } from "react";
import { Title } from "../Title";
import "./History.css";
const History = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("History-saamar");
  const [document] = useAllPrismicDocumentsByType("about_us");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.visionbaner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container eda">
        <div className="row">
          <div className="col-md-6 mt-1">
            <p className="pgr">
              {slice
                ? slice?.body[1]?.primary.historypara[0].text
                : "SIIS was established in the year 2010."}
            </p>
            <p className="pgr">
              {slice
                ? slice?.body[1]?.primary.historypara1[0].text
                : "A nice appealing white building was erected next to its sister concern Jamia Muhammadiyah Mansoora(JMMB) which has been around since the year 1989 offering to its students the state board certification along with a certificate in Religious studies; thereby catering to success in this world and the life to come."}
            </p>
            <p className="pgr">
              {slice
                ? slice?.body[1]?.primary.historypara2[0].text
                : "The International syllabus was adopted so as to give children an opportunity to excel in core subjects in an international setting and also excel at acquiring and applying beneficial knowledge of Islam."}
            </p>
          </div>
          <div className="col-md-6 " style={{ textAlign: "center" }}>
            <img
              src={
                slice
                  ? slice?.body[1]?.primary.historyimg.url
                  : "http://www.saamar.com/image/journal/article?img_id=2526447&t=1596100407558"
              }
              alt=""
              className="gty"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default History;
