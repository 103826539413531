import Aos from "aos";
import { useEffect } from "react";
import "./Education.css";

const Education = (econtent: any) => {
  console.log(econtent);
  useEffect(() => {
    Aos.init();
  });

  return (
    <div className="sct">
      <div className="container">
        <div className="row">
          <div className="col-md-6" data-aos="fade-right">
            <h3 className="saamar-con-col-h3">
              {econtent && econtent?.econtent?.body[1]?.items[0]?.edut1[0].text}
            </h3>
            <p className="col-h3-p">
              <span className="dft">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[1]?.edut1[0].text}
              </span>
              <span className="xtr">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[1]?.edut2[0].text}
              </span>
            </p>
            <p className="col-h3-p">
              <span className="dft">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[2]?.edut1[0].text}
              </span>
              <span className="xtr">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[2]?.edut2[0].text}
              </span>
            </p>
            <p className="site-padd-sub-p">
              <span className="dft">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[3]?.edut1[0].text}
              </span>
              <span className="xtr2">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[3]?.edut2[0].text}
              </span>
            </p>
            <p className="site-padd-sub-p">
              <span className="dft">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[4]?.edut1[0].text}
              </span>
              <span className="xtr2">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[4]?.edut2[0].text}
              </span>
            </p>
            <p className="site-padd-sub-p">
              <span className="dft">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[5]?.edut1[0].text}
              </span>
              <span className="xtr2">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[5]?.edut2[0].text}
              </span>
            </p>
            <p className="site-padd-sub-p">
              <span className="dft">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[6]?.edut1[0].text}
              </span>
              <span className="xtr2">
                {econtent &&
                  econtent?.econtent?.body[1]?.items[6]?.edut2[0].text}
              </span>
            </p>
          </div>
          <div className="col-md-6" data-aos="fade-right">
            <img
              src={econtent && econtent?.econtent?.body[1]?.primary.eduimg.url}
              alt=""
              className="tg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Education;
