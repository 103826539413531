import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Parent.css";
const Parent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("To Parents-saamar");
  const [document] = useAllPrismicDocumentsByType("information");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-5 mt-3" style={{ textAlign: "justify" }}>
        <h4 className="parent-head">{slice?.body[0]?.items[0].head[0].text}</h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[0].list[0].text}
          </li>
        </ul>
        <h4 className="parent-head">{slice?.body[0]?.items[1].head[0].text}</h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[6].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[7].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[8].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[9].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[10].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[11].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[1].list[12].text}
          </li>
        </ul>
        <h4 className="parent-head">{slice?.body[0]?.items[2].head[0].text}</h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[6].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[7].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[8].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[9].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[2].list[10].text}
          </li>
        </ul>
        <h4 className="parent-head">{slice?.body[0]?.items[3].head[0].text}</h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[3].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[3].list[1].text}
          </li>
        </ul>
        <h4 className="parent-head">{slice?.body[0]?.items[4].head[0].text}</h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[4].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[4].list[1].text}
          </li>
        </ul>
        <h4 className="parent-head">{slice?.body[0]?.items[5].head[0].text}</h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[5].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[5].list[1].text}
          </li>
        </ul>
        <h4 className="parent-head">{slice?.body[0]?.items[6].head[0].text}</h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[6].list[0].text}
          </li>
        </ul>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: "13%" }}>SL.No</th>
              <th style={{ width: "25%" }}>Name</th>
              <th>Purpose</th>
            </tr>
          </thead>
          <tbody className="table-parent-data">
            <tr>
              <td>1</td>
              <td>{slice?.body[0]?.items[7].head[0].text}</td>
              <td>{slice?.body[0]?.items[7].list[0].text}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>{slice?.body[0]?.items[8].head[0].text}</td>
              <td>{slice?.body[0]?.items[8].list[0].text}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>{slice?.body[0]?.items[9].head[0].text}</td>
              <td>{slice?.body[0]?.items[9].list[0].text}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>{slice?.body[0]?.items[10].head[0].text}</td>
              <td>{slice?.body[0]?.items[10].list[0].text}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>{slice?.body[0]?.items[11].head[0].text}</td>
              <td>{slice?.body[0]?.items[11].list[0].text}</td>
            </tr>
          </tbody>
        </table>
        <h4 className="parent-head pt-4">
          {slice?.body[0]?.items[12].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[12].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[12].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[12].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[12].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[12].list[4].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[13].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[6].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[7].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[8].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[13].list[9].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[14].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[14].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[14].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[14].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[14].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[14].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[14].list[5].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[15].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[15].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[15].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[15].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[15].list[3].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[16].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[16].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[16].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[16].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[16].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[16].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[16].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[16].list[6].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[17].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[17].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[17].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[17].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[17].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[17].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[17].list[5].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[18].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[6].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[18].list[7].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[19].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[19].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[19].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[17].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[19].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[19].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[19].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[19].list[6].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[20].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[20].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[20].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[20].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[20].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[20].list[4].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[20].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[21].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[21].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[21].list[2].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[22].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[6].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[7].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[8].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[9].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[10].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[11].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[12].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[13].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[14].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[15].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[22].list[16].text}
          </li>
        </ul>
        <h4 className="parent-head">
          {slice?.body[0]?.items[23].head[0].text}
        </h4>
        <ul>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[0].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[1].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[2].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[3].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[4].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[5].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[6].text}
          </li>
          <li className="parent-txt">
            {slice?.body[0]?.items[23].list[7].text}
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Parent;
