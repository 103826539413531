import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Keydetails.css";
import Parents from "./Parents";
const Keydetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Key details-saamar");
  const [document] = useAllPrismicDocumentsByType("about_us");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.visionbaner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container pt-5 mt-2" style={{ textAlign: "justify" }}>
        <h4 className="asp">
          {slice
            ? slice?.body[3]?.items[0].keytext[0].text
            : "A) School prayer"}
        </h4>
        <p className="dsp">
          {slice
            ? slice?.body[3]?.items[1].keytext[0].text
            : "I take refuge in Allah, The Most High;"}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[1].keytext[1].text
            : "His name I mention before anything I try."}
          <br />
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[1].keytext[2].text
            : "All praise be to Him who created me,"}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[1].keytext[3].text
            : "Gave me friends and family."}
          <br />
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[1].keytext[4].text
            : "I thank Him for all that He’s given me."}
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[5].text
            : " My school, my teachers, my ability."}
          <br />
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[6].text
            : " To speak, to listen, to read and write;"}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[1].keytext[7].text
            : "To know what’s wrong, and what’s right"}
          <br />
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[1].keytext[8].text
            : "At school I learn to share and care;"}
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[9].text
            : " To speak the truth, I must dare."}
          <br />
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[10].text
            : " In hard work, faith and courage I trust,"}
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[11].text
            : "To gain beneficial knowledge is a must."}
          <br />
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[12].text
            : "I will watch my every action and deed;"}
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[13].text
            : "and help all those who are in need."}
          <br />
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[14].text
            : "I will strive to be virtuous; I will strive to be wise."}
          <br />
          {slice
            ? slice?.body[3]?.items[1].keytext[15].text
            : "I will aim for the highest level of paradise. Aameen"}
          <br />
          <br />
          <span className="snp">Note:</span>
          {slice
            ? slice?.body[3]?.items[1].keytext[16].text
            : "Allah is the Arabic Word for The God (Al-The, Ilah-One God)"}
        </p>
        <h4 className="asp">
          {slice
            ? slice?.body[3]?.items[2].keytext[0].text
            : "B) THE PQRST MODEL"}
        </h4>
        <h4 className="asp">
          {slice
            ? slice?.body[3]?.items[3].keytext[0].text
            : "Parents – Quality – Resources – Students – Teachers"}
        </h4>
        <p className="dspp">
          {slice
            ? slice?.body[3]?.items[4].keytext[0].text
            : "The PQRST model was devised keeping in mind the basic essentials that can contribute to success of a school collectively and every component of the model individually."}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[5].keytext[0].text
            : "Having identified the components and the KEY ingredient , it is important to list the details that each component must fulfill."}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[6].keytext[0].text
            : "Keeping in mind that Parents-Students-Teachers all aim at achieving"}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[7].keytext[0].text
            : "THREE things ………."}
          <br />
          {slice
            ? slice?.body[3]?.items[7].keytext[0].text
            : " 1. Knowledge – Beneficial (Ilmun naafiun)"}
          <br />
          {slice
            ? slice?.body[3]?.items[8].keytext[0].text
            : " 2. Provision-Pure (Rizqan tayyibun)"}
          <br />
          {slice
            ? slice?.body[3]?.items[9].keytext[0].text
            : "3. Deeds- Accepted (Amalun mutaqabbalun)"}
          <br />
          {slice
            ? slice?.body[3]?.items[10].keytext[0].text
            : "This agreement aims at outlining what must be achieved and how we will work together to achieve the same. It also mentions what our rights and responsibilities are and what we"}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[11].keytext[0].text
            : "may expect from each other ; thereby working collectively towards attaining success in this world and the hereafter."}
          <br />
          {slice
            ? slice?.body[3]?.items[12].keytext[0].text
            : "At all time we must:"}
          <br />
          <span className="mlo">
            {slice ? slice?.body[3]?.items[13].keytext[0].text : " A"}
          </span>
          <span>
            {slice
              ? slice?.body[3]?.items[14].keytext[0].text
              : " -Analyse our needs (i. please our lord ii. offer the best to humanity)"}{" "}
          </span>
          <br />
          <span className="mlo">
            {slice ? slice?.body[3]?.items[15].keytext[0].text : "I"}
          </span>
          <span>
            {slice
              ? slice?.body[3]?.items[16].keytext[0].text
              : " -Improve our skills (i. Communication ii. Organisation)"}
          </span>
          <br />
          <span className="mlo">
            {slice ? slice?.body[3]?.items[17].keytext[0].text : "D"}
          </span>
          <span>
            {slice
              ? slice?.body[3]?.items[18].keytext[0].text
              : " –Develop our profiles (i. Human begin ii.Muslim (one who submits)"}
          </span>
          <br />
          {slice
            ? slice?.body[3]?.items[19].keytext[0].text
            : "  Keeping in mind that the human being depends on both appreciation and advice ; we must all ensure that our interactions/ feedback to each other is based on the structure of :"}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[20].keytext[0].text
            : "1. The PNP model: positive- negative –positive model."}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[21].keytext[0].text
            : "2. The three W format: W+: what went well W-: what went wrong W!: what will work"}
          <br />{" "}
          {slice
            ? slice?.body[3]?.items[22].keytext[0].text
            : "3. Platforms of communication:"}
        </p>
        <table className="table table-bordered key-details-table">
          <thead>
            <tr>
              <th>Platform</th>
              <th>Party A</th>
              <th>Party B</th>
              <th>Outcome /Feelings</th>
            </tr>
          </thead>
          <tbody className="tbt-data">
            <tr>
              <td>
                {" "}
                {slice
                  ? slice?.body[3]?.items[23].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[24].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[25].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[26].keytext[0].text
                  : "Platform 1"}
              </td>
            </tr>
            <tr>
              <td>
                {slice
                  ? slice?.body[3]?.items[27].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[28].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[29].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[30].keytext[0].text
                  : "Platform 1"}
              </td>
            </tr>
            <tr>
              <td>
                {slice
                  ? slice?.body[3]?.items[31].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[32].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[33].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[34].keytext[0].text
                  : "Platform 1"}
              </td>
            </tr>
            <tr>
              <td>
                {slice
                  ? slice?.body[3]?.items[35].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[36].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[37].keytext[0].text
                  : "Platform 1"}
              </td>
              <td>
                {slice
                  ? slice?.body[3]?.items[38].keytext[0].text
                  : "Platform 1"}
              </td>
            </tr>
          </tbody>
        </table>
        <h4 className="asp">
          {slice ? slice?.body[3]?.items[39].keytext[0].text : "THE SCHOOL"}
        </h4>
        <p className="dspp">
          {slice
            ? slice?.body[3]?.items[40].keytext[0].text
            : "SAAMAR International Islamic School (SIIS) will:"}
        </p>
        <p className="dspp mt-0">
          <ol>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[41].keytext[0].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[41].keytext[1].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[41].keytext[2].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[41].keytext[3].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>

            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[41].keytext[4].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[41].keytext[5].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
          </ol>
        </p>
        <h4 className="asp">
          {slice ? slice?.body[3]?.items[42].keytext[0].text : "THE SCHOOL"}
        </h4>
        <p className="dspp">
          {slice
            ? slice?.body[3]?.items[43].keytext[0].text
            : "SAAMAR International Islamic School (SIIS) will:"}
        </p>
        <p className="dspp">
          <ol>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[44].keytext[0].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[44].keytext[1].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[44].keytext[2].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[44].keytext[3].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>

            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[44].keytext[4].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
            <li className="tbt">
              {slice
                ? slice?.body[3]?.items[44].keytext[5].text
                : "SAAMAR International Islamic School (SIIS) will:"}
            </li>
          </ol>
        </p>
        <Parents pdata={slice} />

        {/* <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td className="text-truncate">@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td className="text-truncate">@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td className="text-truncate">@twitter</td>
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
};
export default Keydetails;
