import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Announcementc.css";
const Announcement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Announcements - saamar");
  const [document] = useAllPrismicDocumentsByType("notice_board");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-4 mt-4">
        <p className="announ-txt">{slice?.body[3]?.primary.title[0].text} </p>
        <div className="row">
          <div className="col-md-6">
            <img
              src={slice?.body[3]?.items[0].announcementimg.url}
              alt=""
              className="notice-announ-img"
            />
          </div>
          <div className="col-md-6">
            <img
              src={slice?.body[3]?.items[1].announcementimg.url}
              alt=""
              className="notice-announ-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Announcement;
