import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Criteria.css";
const Criteria = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Criteria-saamar");
  const [document] = useAllPrismicDocumentsByType("admission");
  const slice = document && document[0]?.data;
  console.log(slice);

  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.bannerimg.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container mpu pt-4">
        <h4 className="crit-head">
          {slice
            ? slice?.body[0]?.items[0].cricpara[0].text
            : "At SIIS…………………….YOUR child is OUR responsibility. "}
        </h4>
        <h4 className="crit-text">
          {slice
            ? slice?.body[0]?.items[0].crictopic[0].text
            : "Admission criteria:"}
        </h4>

        <p className="crit-para">
          {slice
            ? slice?.body[0]?.items[1].cricpara[0].text
            : "At SAAMAR INTERNATIONAL ISLAMIC School, we consider each child unique. We understand and encourage the parent's concern for the child."}
          <br />
          <br />
          {slice
            ? slice?.body[0]?.items[1].crictopic[0].text
            : "SIIS is affiliated to Cambridge Assessment International Education, Cambridge, UK. Reg No: IN362. It is recognized by the Govt. of Karnataka."}
        </p>
        <p className="crit-para">
          {slice
            ? slice?.body[0]?.items[2].cricpara[0].text
            : "Admission to SIIS is based on a First come-First Serve basis."}
          <br />
          <br />
          {slice
            ? slice?.body[0]?.items[2].crictopic[0].text
            : "Please see details below for the criteria:"}
        </p>
        <p className="crit-log">
          {slice
            ? slice?.body[0]?.items[7].crictopic[0].text
            : "The criteria can simply be remembered as CAP."}
        </p>
        <p>
          <span className="crit-info">
            {slice ? slice?.body[0]?.items[3].cricpara[0].text : "C"}
          </span>
          <span className="crit-para">
            {slice
              ? slice?.body[0]?.items[3].crictopic[0].text
              : "for Current performance"}
          </span>
        </p>
        <p>
          <span className="crit-info">
            {slice ? slice?.body[0]?.items[4].cricpara[0].text : "A"}
          </span>
          <span className="crit-para">
            {slice
              ? slice?.body[0]?.items[4].crictopic[0].text
              : " for age appropriateness"}
          </span>
        </p>
        <p>
          <span className="crit-info">
            {slice ? slice?.body[0]?.items[5].cricpara[0].text : "P"}
          </span>
          <span className="crit-para">
            {slice
              ? slice?.body[0]?.items[5].crictopic[0].text
              : "for previous performance"}
          </span>
        </p>
        <div className="container">
          <p className="crit-info">
            {slice
              ? slice?.body[0]?.items[6].cricpara[0].text
              : "Current performance:"}
          </p>
          <p className="crit-para">
            {" "}
            {slice
              ? slice?.body[0]?.items[6].crictopic[0].text
              : "It is very important for us to know where your child stands and then give you a clear picture of where we can take him/her to.              "}
          </p>
          <p className="crit-info">
            {slice
              ? slice?.body[0]?.items[7].cricpara[0].text
              : "FOR GRADES 1 AND ABOVE:"}
          </p>
          <p>
            <span className="crit-para">
              {slice
                ? slice?.body[0]?.items[8].cricpara[0].text
                : "We take Diagnostic tests in four subjects"}
            </span>
            <span className="crit-log">
              {slice
                ? slice?.body[0]?.items[8].crictopic[0].text
                : "It can be easily remembered as MESH."}
            </span>
          </p>
          <p>
            <span className="crit-info">
              {slice ? slice?.body[0]?.items[9].cricpara[0].text : "M"}
            </span>
            <span className="crit-para">
              {slice
                ? slice?.body[0]?.items[9].crictopic[0].text
                : "for Mathematics"}
            </span>
          </p>
          <p>
            <span className="crit-info">
              {slice ? slice?.body[0]?.items[10].cricpara[0].text : "E"}
            </span>
            <span className="crit-para">
              {slice
                ? slice?.body[0]?.items[10].crictopic[0].text
                : "for English"}
            </span>
          </p>
          <p>
            <span className="crit-info">
              {slice ? slice?.body[0]?.items[11].cricpara[0].text : "S"}
            </span>
            <span className="crit-para">
              {slice
                ? slice?.body[0]?.items[11].crictopic[0].text
                : "for Science"}
            </span>
          </p>
          <p>
            <span className="crit-info">
              {slice ? slice?.body[0]?.items[12].cricpara[0].text : "H"}
            </span>
            <span className="crit-para">
              {slice
                ? slice?.body[0]?.items[12].crictopic[0].text
                : "for Hindi"}
            </span>
          </p>
          <p className="crit-para">
            {slice
              ? slice?.body[0]?.items[13].cricpara[0].text
              : "If a child is far behind the starting point, then we meet with you to discuss the same and plan a support strategy, undertaking, etc"}
          </p>
          <p className="crit-info">
            {slice
              ? slice?.body[0]?.items[13].crictopic[0].text
              : "FOR THE MONTESORI SECTION:"}
          </p>
          <p className="crit-para">
            {slice
              ? slice?.body[0]?.items[14].cricpara[0].text
              : "The evaluation is based on KAS."}
          </p>
          <p className="crit-para">
            {slice
              ? slice?.body[0]?.items[14].crictopic[0].text
              : "Knowledge – of the things around him/her under Literacy and Numeracy"}
          </p>
          <p className="crit-para">
            {slice
              ? slice?.body[0]?.items[15].cricpara[0].text
              : "Application – of the knowledge in small simple questions"}
          </p>
          <p className="crit-para">
            {slice
              ? slice?.body[0]?.items[15].crictopic[0].text
              : "Skills – Listening, Speaking, Reading, Writing (LSRW)"}
          </p>
          <p className="crit-info">
            {slice ? slice?.body[0]?.items[16].cricpara[0].text : "2.Age"}
          </p>
          <p className="crit-para">
            {slice
              ? slice?.body[0]?.items[16].crictopic[0].text
              : "A child’s age must be in accordance with the grade that he /she is seeking admission to. "}
          </p>
          <p className="crit-para">
            <ul>
              <li>
                {slice
                  ? slice?.body[0]?.items[17].crictopic[0].text
                  : "Nursery/Mont 1: 3 years"}
              </li>
              <li>
                {slice
                  ? slice?.body[0]?.items[17].crictopic[1].text
                  : "LKG/Mont 2: 4 years"}
              </li>
              <li>
                {slice
                  ? slice?.body[0]?.items[17].crictopic[2].text
                  : "UKG/Mont 3: 5 years"}
              </li>
              <li>
                {slice
                  ? slice?.body[0]?.items[17].crictopic[3].text
                  : "Grade 1: 6 years and so on"}
              </li>
            </ul>
          </p>
          <p className="crit-info">
            {slice ? slice?.body[0]?.items[17].cricpara[0].text : "2.Age"}
          </p>
          <p className="crit-para crit-info-para">
            {slice
              ? slice?.body[0]?.items[18].crictopic[0].text
              : "It is also important for us to know what the child has already studied especially the languages and the concepts. We do ask for the previous year’s Progress Report to check performance and grades especially in MESH. (M for Mathematics, E for English, S for Science, H for Hindi)"}
          </p>
          <p>
            <span className="crit-info">
              {slice
                ? slice?.body[0]?.items[18].cricpara[0].text
                : "Syllabus stream is something we check on."}
            </span>
            <span className="crit-para crit-info-para">
              {slice
                ? slice?.body[0]?.items[19].crictopic[0].text
                : "For example, a child who has done the State syllabus upto grade 7 will not be granted admission to grade 8 as he/she has to study the IGCSE syllabus. It is a totally different ladder to climb and would require the child to move on as well. We would therefore suggest to you to continue with state syllabus. Currently, we too offer state syllabus upto grade 5."}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Criteria;
