import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { Title } from "../Title";
import "./Environment.css";
const Environment = () => {
  Title("Environment-saamar");
  const [document] = useAllPrismicDocumentsByType("infrastructure");
  const slice = document && document[0]?.data;
  console.log(slice);

  return (
    <div>
      <img
        src={
          slice
            ? slice?.body[0]?.primary.ebanner.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container pt-4 mt-4" style={{ textAlign: "justify" }}>
        <ul>
          <li className="sit">{slice?.body[0]?.items[0].etext[0].text}</li>
          <li className="sit">{slice?.body[0]?.items[0].etext[1].text}</li>
          <li className="sit">{slice?.body[0]?.items[0].etext[2].text}</li>
          <li className="sit">{slice?.body[0]?.items[0].etext[3].text}</li>
          <li className="sit">{slice?.body[0]?.items[0].etext[4].text}</li>
          <li className="sit">{slice?.body[0]?.items[0].etext[5].text}</li>
          <li className="sit">{slice?.body[0]?.items[0].etext[6].text}</li>
        </ul>

        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[0]?.items[0].eimg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[0]?.items[0].etext[7].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[0]?.items[1].eimg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
        <ul>
          <li className="sit">{slice?.body[0]?.items[0].etext[8].text}</li>
        </ul>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <img
              src={slice?.body[0]?.items[2].eimg.url}
              alt=""
              className="qzr"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Environment;
