import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Circular.css";
const Circular = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("Circulars - saamar");
  const [document] = useAllPrismicDocumentsByType("notice_board");
  const slice = document && document[0]?.data;
  console.log(slice);
  return (
    <div>
      {" "}
      <img src={slice?.body[0]?.primary.banner.url} alt="" className="sgi" />
      <div className="container pt-5">
        <p className="circular-txt">
          Dear Parents, below is the text of the circular/SMS that was sent to
          you through your child or on your number.
        </p>
        <table className="table table-bordered cir-table">
          <thead></thead>
          <tbody className="circle-table-data">
            <tr>
              <td>1</td>
              <td>{slice?.body[2]?.items[0].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[0].text[0].text}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>{slice?.body[2]?.items[1].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[1].text[0].text}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>{slice?.body[2]?.items[2].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[2].text[0].text}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>{slice?.body[2]?.items[3].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[3].text[0].text}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>{slice?.body[2]?.items[4].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[4].text[0].text}</td>
            </tr>
            <tr>
              <td>7</td>
              <td>{slice?.body[2]?.items[6].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[6].text[0].text}</td>
            </tr>
            <tr>
              <td>8</td>
              <td>{slice?.body[2]?.items[7].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[7].text[0].text}</td>
            </tr>
            <tr>
              <td>9</td>
              <td>{slice?.body[2]?.items[8].cdate[0].text}</td>
              <td>{slice?.body[2]?.items[8].text[0].text}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Circular;
