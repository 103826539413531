import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import "./Form.css";
const Form = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [document] = useAllPrismicDocumentsByType("admission");
  const slice = document && document[0]?.data;
  console.log(slice);

  return (
    <div>
      {" "}
      <img
        src={
          slice
            ? slice?.body[0]?.primary.bannerimg.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container mt-4 pt-3">
        <div className="form-li">
          <ol>
            <li>
              {slice
                ? slice?.body[2]?.items[0].formtext[0].text
                : "Soft copy of Application form"}
            </li>
            <li>
              {slice
                ? slice?.body[2]?.items[0].formtext[1].text
                : "Know Your Parent (KYP) forms."}
            </li>
            <li>
              {slice
                ? slice?.body[2]?.items[0].formtext[2].text
                : "Transport Application Form"}
            </li>
            <li>
              {slice
                ? slice?.body[2]?.items[0].formtext[3].text
                : "Private Transport Declaration Form(PTDF)"}
            </li>
            <li>
              {slice
                ? slice?.body[2]?.items[0].formtext[4].text
                : "Know Your Student (KYS) Form"}
            </li>
            <li>
              {slice
                ? slice?.body[2]?.items[0].formtext[5].text
                : "Online Application – Google form"}
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
export default Form;
