import Aos from "aos";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import "./Banner.css";

const Banner = (bcontent: any) => {
  useEffect(() => {
    Aos.init();
  });
  const items = [
    {
      src: `${bcontent?.bcontent?.body[0]?.items[0].bannerimg.url}`,

      caption: `${bcontent?.bcontent?.body[0]?.items[0].bannertext[0].text}`,
      key: 1,
    },
    {
      src: `${bcontent?.bcontent?.body[0]?.items[1].bannerimg.url}`,

      caption: `${bcontent?.bcontent?.body[0]?.items[1].bannertext[0].text}`,
      key: 2,
    },
    {
      src: `${bcontent?.bcontent?.body[0]?.items[2].bannerimg.url}`,

      caption: `${bcontent?.bcontent?.body[0]?.items[2].bannertext[0].text}`,
      key: 3,
    },
    {
      src: `${bcontent?.bcontent?.body[0]?.items[3].bannerimg.url}`,

      caption: `${bcontent?.bcontent?.body[0]?.items[3].bannertext[0].text}`,
      key: 4,
    },
    {
      src: `${bcontent?.bcontent?.body[0]?.items[4].bannerimg.url}`,

      caption: `${bcontent?.bcontent?.body[0]?.items[4].bannertext[0].text}`,
      key: 5,
    },
    {
      src: `${bcontent?.bcontent?.body[0]?.items[5].bannerimg.url}`,
      altText: "",
      caption: "",
      key: 6,
    },
    {
      src: `${bcontent?.bcontent?.body[0]?.items[6].bannerimg.url}`,
      altText: "",
      caption: "",
      key: 7,
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex: React.SetStateAction<number>) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="gg" />
        <div data-aos="fade-right">
          <CarouselCaption
            captionText={item.caption}
            captionHeader={item.caption}
          />
        </div>
      </CarouselItem>
    );
  });
  return (
    <div className="marq md-mt-200">
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        {...items}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
      <Marquee className="mog_cont">
        SAAMAR INTERNATIONAL ISLAMIC SCHOOL
      </Marquee>
    </div>
  );
};
export default Banner;
