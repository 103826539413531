import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useEffect } from "react";
import { Title } from "../Title";
import "./Nationalevents.css";
const normalData = [
  {
    src: "/saamarumages/nationalevent1.png",
  },
  {
    src: "/saamarumages/nationalevent2.png",
  },
  {
    src: "/saamarumages/nationalevent3.png",
  },
  {
    src: "/saamarumages/nationalevent4.png",
  },
  {
    src: "/saamarumages/nationalevent5.png",
  },
];
const Nationalevents = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  Title("National events - saamar");
  const [document] = useAllPrismicDocumentsByType("gallery");
  const slice = document && document[0]?.data;
  console.log(slice);
  const NormalData = normalData.map((item: any, i: any) => {
    return (
      <div className="col-md-4" key={i}>
        <img src={item.src} className="gallery-national-img" />
      </div>
    );
  });
  const PrismicData = slice?.body[0]?.items?.map((item: any, i: any) => {
    return (
      <div className="col-md-4" key={i}>
        <img src={item.neimg.url} className="gallery-national-img" />
      </div>
    );
  });

  return (
    <div>
      {" "}
      <img
        src={
          slice
            ? slice?.body[0]?.primary.bannerimg.url
            : "http://www.saamar.com/image/journal/article?img_id=2527611&t=1597657739174"
        }
        alt=""
        className="sgi"
      />
      <div className="container pt-5">
        <div className="row">{slice ? PrismicData : NormalData}</div>
      </div>
    </div>
  );
};
export default Nationalevents;
